<template>
  <div
    class="d-flex justify-space-between flex-column pa-6"
    style="height: 100%"
  >
    <div>
      <v-row dense>
        <v-col :cols="12" :xl="6" v-if="!userFederations">
          <!-- NEW FEDERATION BUTTON -->
          <v-btn
            @click="openNewFedDialog"
            width="100%"
            color="primary"
            class="vcad-btn federation-action-btn remove-vmargin"
            outlined
            v-if="!userFederations"
          >
            <v-icon> mdi-plus-box </v-icon>New federation
          </v-btn>
        </v-col>
        <v-col :cols="12" :xl="6">
          <!-- TEMPLATE BUTTON -->
          <v-btn
            class="vcad-btn federation-action-btn remove-vmargin"
            color="primary"
            width="100%"
            outlined
            :disabled="!selectedMMProject"
            @click="openTemplate"
          >
            <v-icon>mdi-file-document</v-icon> Template</v-btn
          >
        </v-col>
        <v-col :cols="12" :xl="6">
          <!-- RENAME PROJECT -->
          <v-btn
            :disabled="selectedMMProject == null"
            @click="
              mmProjectEdit = JSON.parse(JSON.stringify(selectedMMProject));
              showDialogEdit = true;
            "
            width="100%"
            color="primary"
            class="vcad-btn federation-action-btn remove-vmargin"
            outlined
            ><v-icon>mdi-rename-box</v-icon>
            Edit Name
          </v-btn>
        </v-col>
        <v-col
          :cols="12"
          :xl="6"
          :class="userFederations ? 'd-contents' : 'd-block'"
        >
          <!-- DELETE FEDERATION -->
          <v-btn
            :disabled="selectedMMProject == null"
            @click="showDeleteProjectDialog = true"
            class="vcad-btn federation-action-btn remove-vmargin"
            width="100%"
            outlined
            color="error darken-1"
            ><v-icon>mdi-delete</v-icon>Delete Federation</v-btn
          >
        </v-col>
      </v-row>
      <vcad-dialog title="New Federation" v-model="showDialogNew">
        <template v-slot:content>
          <!-- <multimodel-project-add-update
            :mmProject="mmProjectEdit"
            @onCancel="onFormCancel"
            @onSave="saveNewFederation"
          ></multimodel-project-add-update> -->
          <v-form ref="form" class="mx-2">
            <v-text-field
              v-model="mmProjectEdit.projectName"
              label="Name *"
              required
              :rules="[minLenghtName]"
            ></v-text-field
            ><v-text-field
              v-model="mmProjectEdit.projectDescription"
              label="Description"
            ></v-text-field>

            <div class="d-flex justify-end">
              <v-btn
                class="vcad-btn"
                small
                outlined
                color=""
                @click="onFormCancel"
              >
                Close
              </v-btn>
              <v-btn
                class="vcad-btn"
                small
                outlined
                color="primary"
                :loading="buttonLoader"
                @click="saveNewFederation"
              >
                Save
              </v-btn>
            </div>
          </v-form>
        </template>
      </vcad-dialog>

      <vcad-dialog title="Edit Federation" v-model="showDialogEdit">
        <template v-slot:content>
          <v-form ref="form" class="mx-2">
            <v-text-field
              v-model="mmProjectEdit.projectName"
              label="Name *"
              required
              :rules="[minLenghtName]"
            ></v-text-field
            ><v-text-field
              v-model="mmProjectEdit.projectDescription"
              label="Description"
            ></v-text-field>

            <div class="d-flex justify-end">
              <v-btn
                class="vcad-btn"
                small
                outlined
                color=""
                @click="onFormCancel"
              >
                Close
              </v-btn>
              <v-btn
                class="vcad-btn"
                small
                outlined
                color="primary"
                :loading="buttonLoader"
                @click="updateFederation"
              >
                Save
              </v-btn>
            </div>
          </v-form>
        </template>
      </vcad-dialog>

      <!-- SELECT FEDERATION -->
      <v-col style="padding: 2px 0 !important" v-if="!userFederations">
        <v-autocomplete
          item-color="secondary"
          color="secondary"
          class="mt-3"
          label="Select federation"
          item-text="projectName"
          :loading="loadingProj"
          key="multimodelProjectGuid"
          return-object
          dense
          v-if="folderMMProjects.length > 0"
          :items="folderMMProjects"
          v-bind:value="selectedMMProject"
          v-on:input="setSelectedMMProject"
          outlined
        ></v-autocomplete>
      </v-col>
    </div>
    <div>
      <!-- INFO PANEL -->
      <div v-if="selectedMMProject">
        <h4 class="text-subtitle-1 mb-3">Info</h4>
        <v-row
          class="info-row"
          v-for="(info, key) in infoItemsTemplate"
          :key="key"
        >
          <v-col :cols="3" class="font-weight-bold pt-1 pb-1">{{
            info.propDescr
          }}</v-col>
          <v-col :cols="9" class="text-body-2 pt-1 pb-1 elevation-0">
            {{ info.propValue }}
          </v-col>
        </v-row>
      </div>

      <vcad-dialog
        v-model="showTemplateDialog"
        title="Templates"
        dialogWidth="1100"
        :iconImg="dialogIcon"
      >
        <template v-slot:content>
          <fed-vcad-template
            :vcadViewList="vcadTemplateViewList"
            :vcadData="vcadTemplateData"
            :federatedSlotGuids="federatedSlotGuids"
          ></fed-vcad-template>
        </template>
        <template v-slot:actions>
          <div class="d-inline-flex justify-end" style="width: 100%">
            <v-btn
              outlined
              small
              color=""
              class="vcad-btn"
              @click.stop="showTemplateDialog = false"
              >CLOSE</v-btn
            >
            <!-- <v-btn
                outlined
                small
                @click="downloadCsvTemplate()"
                color="primary"
                class="vcad-btn"
                ><v-icon>mdi-download-box</v-icon
                ><span>Download csv</span></v-btn
              > -->
          </div>
        </template>
      </vcad-dialog>

      <vcad-dialog
        title="Delete federation"
        v-if="selectedMMProject"
        v-model="showDeleteProjectDialog"
        persistent
        max-width="600px"
      >
        <template v-slot:content="{}">
          <p>
            Delete federation {{ selectedMMProject.projectName }}? Confirm to
            continue
          </p>
        </template>
        <template v-slot:actions="{}">
          <div class="d-inline-flex justify-end mt-2" style="width: 100%">
            <v-btn
              class="vcad-btn"
              small
              outlined
              @click="showDeleteProjectDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              class="vcad-btn"
              color="primary"
              small
              outlined
              @click="deleteProject"
              >Confirm</v-btn
            >
          </div>
        </template>
      </vcad-dialog>
      <vcad-snackbar
        :value="showDeleteMsg"
        :text="'Federation deleted'"
        :customTimeout="2000"
        customColor="primary"
        @closeSnack="showDeleteMsg = false"
      ></vcad-snackbar>
      <vcad-snackbar
        :value="renameSnackBar"
        :text="'Federation successfully renamed'"
        :customTimeout="2000"
        customColor="primary"
        @closeSnack="renameSnackBar = false"
      ></vcad-snackbar>
      <vcad-snackbar
        :value="showAlertSnackbar"
        :text="alertMsg"
        :customTimeout="4000"
        customColor="error"
        @closeSnack="showAlertSnackbar = false"
      ></vcad-snackbar>
      <vcad-snackbar
        :value="showErorSnackbar"
        :text="errorMsg"
        :customTimeout="2000"
        customColor="error"
        @closeSnack="showErorSnackbar = false"
      ></vcad-snackbar>
    </div>
  </div>
</template>

<script >
import { mapGetters, mapActions } from "vuex";
import VcadDialog from "../../../components/VcadDialog";
import VcadTemplate2 from "../../VcadTemplate2.vue";
import VcadSnackbar from "../../../components/VcadSnackbar";
import { v4 as uuidv4 } from "uuid";
import { getFileIconExt } from "../../../utils/GetFileIconExtension";

export default {
  components: {
    "fed-vcad-template": VcadTemplate2,
    "vcad-dialog": VcadDialog,
    "vcad-snackbar": VcadSnackbar,
  },
  props: {
    userFederations: {
      type: Array,
    },
    activeFolderName: {
      type: String,
      // required: true,
    },
    mmFiles: {
      type: Array,
    },
    folderItemId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.loadingProj = true;
    try {
      if (this.selectedFolderMMGUID) {
        await this.setFolderMMProjects({
          folderGuid: this.selectedFolderMMGUID,
        });
      }
      this.setFirstProject();
    } catch (error) {
      console.error(error);
    }
    this.loadingProj = false;
  },
  computed: {
    ...mapGetters([
      "folderMMProjects",
      "selectedMMProject",
      "selectedFolderMMGUID",
      "mmExtCompatibilityGroup",
      "templateIdToAdd",
      "axiosSettings",
      "selectedProject",
    ]),
    minLenghtName: () => (value) => !!value || "Field is required",
    infoItemsTemplate() {
      let info = [
        {
          propValue: this.selectedMMProject.projectDescription,
          propDescr: "Description:",
        },
        {
          propValue: this.selectedMMProject.multimodelProjectGuid,
          propDescr: "Federation GUID:",
        },
        {
          propValue: this.selectedMMProject.folderName,
          propDescr: "Folder:",
        },
        {
          propValue: this.selectedMMProject.projectName,
          propDescr: "Federation name:",
        },
      ];
      return info;
    },
    federatedSlotGuids() {
      let slotGuids = [];
      if (this.selectedMMProject) {
        slotGuids = this.selectedMMProject.slots.map((slot) => slot.Guid);
      }
      return slotGuids;
    },
    dialogIcon() {
      const iconExt = this.selectedMMProject?.slots[0]?.fileExt;
      const icon = getFileIconExt(iconExt);
      return icon;
    },
  },

  data() {
    return {
      showTemplateDialog: false,
      vcadTemplateData: null,
      vcadTemplateViewList: null,
      showDialogNew: false,
      showDialogEdit: false,
      mmProjectEdit: {},
      showDeleteProjectDialog: false,
      showDeleteMsg: false,
      renameSnackBar: false,
      loadingProj: false,
      showErorSnackbar: false,
      errorMsg: "",
      showAlertSnackbar: false,
      alertMsg: "",
      buttonLoader: false,
    };
  },
  methods: {
    ...mapActions([
      "setVcadTemplateList",
      "setFolderMMProjects",
      "setSelectedMMProject",
      "saveUpdateMMProject",
      "deleteMMProject",
      "addToFolderMMProjects",
      "updateFolderMMProjects",
    ]),
    async openNewFedDialog() {
      const newGUID = uuidv4();

      const emptyFederation = {
        multimodelProjectGuid: newGUID,
        folderGuid: this.selectedFolderMMGUID,
        folderName: null,
        projectName: null,
        projectDescription: null,
        slots: [],
        alignments: [],
      };
      this.mmProjectEdit = emptyFederation;
      this.showDialogNew = true;
    },
    onFormCancel() {
      this.mmProjectEdit = {};
      this.showDialogNew = false;
      this.showDialogEdit = false;
    },
    async saveNewFederation() {
      const newFed = await this.saveUpdateProject(this.mmProjectEdit);
      this.addToFolderMMProjects(newFed);
      this.setSelectedMMProject(newFed);
      this.showDialogNew = false;
    },
    async updateFederation() {
      const newFed = await this.saveUpdateProject(this.mmProjectEdit);

      let tmpFedList = [...this.folderMMProjects];
      let indexToUpdate = tmpFedList.findIndex(
        (fed) => fed.multimodelProjectGuid === newFed.multimodelProjectGuid
      );
      tmpFedList.splice(indexToUpdate, 1, newFed);
      await this.updateFolderMMProjects(tmpFedList);

      this.setSelectedMMProject(newFed);
      this.showDialogEdit = false;
    },
    // downloadCsvTemplate() {},
    async openTemplate() {
      let th = this;

      if (
        this.selectedMMProject &&
        this.selectedMMProject.slots &&
        this.selectedMMProject.slots.length > 0
      ) {
        let distinctExt = [
          ...new Set(
            this.selectedMMProject.slots.map(
              (x) => th.mmExtCompatibilityGroup[x.fileExt]
            )
          ),
        ];
        let gtype = -1;
        if (distinctExt.length == 1) {
          gtype = distinctExt[0];
        } else {
          gtype = 9999; //composition template?
        }

        let templateId =
          Number.parseInt(this.templateIdToAdd) + parseInt(gtype);

        await this.setVcadTemplateList(templateId);

        this.vcadTemplateViewList = this.selectedMMProject.slots[0].viewList;

        const authType = this.selectedMMProject.slots[0].vcadItem?.authType;

        this.vcadTemplateData = {
          name: this.selectedMMProject.projectName,
          authType,
          id: this.selectedMMProject.multimodelProjectGuid,
          type: templateId,
          isFederation: true,
        };

        //this.$refs.vcadTemplate_multimodel.$props.data = data;
        this.showTemplateDialog = true;
      } else {
        this.alertMsg =
          "Cannot download template for an empty federation. Please add at least one model to the federation.";
        this.showAlertSnackbar = true;
      }
    },
    async saveUpdateProject(project) {
      let th = this;
      // const projectId = this.selectedFolder?.projectId;
      // const folderItemId = this.selectedFolder?.itemId;
      let payload = {
        federationData: project,
        projectId: this.projectId,
        folderItemId: this.folderItemId,
      };
      this.buttonLoader = true;
      return th
        .saveUpdateMMProject(payload)
        .then((response) => {
          return JSON.parse(response.data);
        })
        .catch((error) => {
          this.errorMsg = error;
          this.showErorSnackbar = true;
        })
        .finally(() => {
          this.buttonLoader = false;
        });
    },

    deleteProject() {
      this.deleteMMProject(this.selectedMMProject).then(() => {
        this.showDeleteMsg = true;
        this.loadingProj = true;
        this.setFolderMMProjects({
          folderGuid: this.selectedFolderMMGUID,
        })
          .then(() => {
            this.setFirstProject();
            if (this.userFederations) {
              this.$emit("fedDeletedEmit", false);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loadingProj = false;
          });
      });
      this.showDeleteProjectDialog = false;
    },

    setFirstProject() {
      let tmpSelectedProj = !this.userFederations ? this.folderMMProjects[0] : this.selectedMMProject
      this.setSelectedMMProject(tmpSelectedProj)
    },
  },
  beforeDestroy() {
    this.setSelectedMMProject(null);
  },
};
</script>

<style scoped>
.info-row {
  border-top: solid 1px rgba(200, 200, 200, 0.25);
}
.remove-vmargin {
  margin: 3px 0 !important;
}
/* .federation-action-btn{
  flex: 1 1 140px;
} */
.d-contents {
  display: contents;
}
</style>