var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.visibleView)?_c('v-select',{staticClass:"flex-grow-0 mb-2 mt-1",staticStyle:{"width":"50%"},attrs:{"label":"View","return-object":"","item-text":"name","item-key":"guid","items":_vm.vcadCurrentViews,"hint":"Available views and phases","append-outer-icon":"mdi-content-copy","outlined":"","persistent-hint":"","dense":""},on:{"click:append-outer":function($event){return _vm.copyViewGuid(_vm.vcadItem)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"grey--text text-caption",staticStyle:{"margin-top":"2px"}},[_vm._v(" ( "+_vm._s(_vm.vcadCurrentViews.length)+" views) ")]),_c('v-icon',[_vm._v("mdi-menu-down")])]},proxy:true}],null,false,3682248518),model:{value:(_vm.selectedView),callback:function ($$v) {_vm.selectedView=$$v},expression:"selectedView"}}):_vm._e(),_c('v-tabs',{staticClass:"mb-2 mr-1",staticStyle:{"border":"1px solid #f0f0f0"},attrs:{"vertical":"","grow":"","dense":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.tabKey,staticClass:"font-weight-black",staticStyle:{"border-right":"1px solid #f0f0f0"},attrs:{"disabled":tab.isVisible,"dense":""}},[_vm._v(_vm._s(tab.tabName))])}),_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.tabKey,attrs:{"transition":false}},[_c('v-data-table',{staticClass:"vcad-template-table-scrollable rounded-0",attrs:{"headers":_vm.headers,"height":"30vh","show-select":false,"items":_vm.getTemplates(tab.tabName),"item-key":"name","dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label || item.name)+" ")]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._v(" V"+_vm._s(_vm.printVersion(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"vcad-btn ma-2",attrs:{"small":"","color":"primary","disabled":_vm.isDownloading},on:{"click":function($event){return _vm.downloadTemplate(item)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi mdi-cloud-download")]),_vm._v(" template ")],1),(item.isPdfPresent)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.isDownloading,"color":"primary"},on:{"click":function($event){return _vm.downloadHelp(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-pdf-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Download help file")])])]:_vm._e()]}}],null,true)})],1)})],2),_c('div',{staticClass:"d-flex align-center justify-space-between"},[(_vm.authModeOn == true)?_c('v-text-field',{staticClass:"pt-5 flex-grow-0",staticStyle:{"width":"45%"},attrs:{"outlined":"","persistent-hint":"","dense":"","hint":"Key required when loading the extacted data","append-icon":_vm.showKey ? 'mdi-eye' : 'mdi-eye-off',"append-outer-icon":"mdi-content-copy","type":_vm.showKey ? 'text' : 'password',"name":"txtFileKey","label":"API Key","readonly":""},on:{"click:append-outer":_vm.copyText,"click:append":function($event){_vm.showKey = !_vm.showKey}},model:{value:(_vm.fileKey),callback:function ($$v) {_vm.fileKey=$$v},expression:"fileKey"}}):_vm._e(),(_vm.authModeOn == true)?_c('v-text-field',{staticClass:"pt-5 flex-grow-0",staticStyle:{"width":"45%"},attrs:{"outlined":"","persistent-hint":"","dense":"","hint":"","append-outer-icon":"mdi-content-copy","label":"Slot ID","readonly":"","value":_vm.vcadItem.id},on:{"click:append-outer":function($event){return _vm.copySlotIdText(_vm.vcadItem)}}}):_vm._e()],1),(_vm.isDownloading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }