<template>
  <div>
    <div class="d-flex flex-column">
      <div class="mt-5">
        <v-radio-group
          mandatory
          id="dataset-radio-group"
          v-model="selectedDataset"
        >
          <v-radio
            v-for="data in dataset"
            :key="data"
            :label="formatLabel(data)"
            :value="data"
            color="secondary"
            class="ml-4"
            style="text-transform: capitalize"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="align-self-center">
        <v-btn
          outlined
          @click="downloadCsv()"
          color="primary"
          class="vcad-btn ma-4"
          :loading="loadingButton"
          ><v-icon class="mr-1">mdi-download-box</v-icon>Download</v-btn
        >
      </div>
      <v-text-field
        class="pa-4 flex-grow-0"
        outlined
        persistent-hint
        dense
        hint="URL to directly download the data file"
        label="Download URL"
        v-model="getDownloadUrl"
        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
        append-outer-icon="mdi-content-copy"
        v-if="authModeOn == true"
        :type="showKey ? 'text' : 'password'"
        name="txtFileKey"
        readonly
        @click:append-outer="copyText"
        @click:append="showKey = !showKey"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import sanitizefilename from "sanitize-filename";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
// import { vcadDataset } from "../../utils/VcadDatasets";
import { VcadSlotKey } from "../../utils/VcadSlotKey";

export default {
  props: {
    slotGuid: {
      type: String,
      required: false,
    },
    fileId: {
      type: String,
      required: false,
    },
    fileType: {
      type: String,
      required: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    selectedView: {
      type: Object,
      required: false,
    },
    vcadViewList: {
      default: null,
    },
    fileTypeNumber: {
      type: Number,
    },
    vcadItem: {
      required: false,
    },
    federatedSlotGuids: {
      type: Array,
      required: false,
      validator: (propVal) => {
        if (!Array.isArray(propVal)) {
          return false;
        }
        return propVal.every((item) => typeof item === "string");
      },
    },
  },
  components: {},
  data() {
    return {
      loadingButton: false,
      dataset: ["All (zip)"],
      selectedDataset: "All",
      showKey: false,
      baseURL: "",
      fileKey: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["axiosSettings"]),
    authModeOn() {
      return this.vcadItem?.authType == 1 || this.vcadItem?.authType == 3;
    },
    getDownloadUrl() {
      let datasetWithExt =
        this.selectedDataset !== "All (zip)"
          ? this.selectedDataset + ".csv"
          : "";
      let params = {
        guidFile: this.slotGuid,
        type: "csvzip",
        viewableGuid: this.selectedView?.guid,
        entryname: datasetWithExt.toLowerCase(),
      };
      let paramsString = "";

      for (let key in params) {
        paramsString += `${key}=${params[key]}&`;
      }

      paramsString = paramsString.slice(0, -1);
      let urlString = this.baseURL + "/file/GetFile?" + paramsString;

      // Append API key to the urlString
      urlString += `&x-vcad-auth=${this.fileKey}`;
      return urlString;
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    async getBaseUrl() {
      return axios
        .get(
          "api/vcad/file/getUrl",
          JSON.parse(JSON.stringify(this.axiosSettings))
        )
        .then((res) => {
          this.baseURL = res.data;
        });
    },
    async setKey() {
      let fileGuid;
      this.fileKey = "";
      if (this.authModeOn) {
        try {
          // IF the user is downloading a template for a federation
          if (
            this.vcadItem?.isFederation &&
            this.federatedSlotGuids?.length > 0
          ) {
            fileGuid = this.federatedSlotGuids[0];
          } else {
            // IF the user is downloading a template for a single file
            fileGuid = this.vcadItem.id;
          }
          this.fileKey = await VcadSlotKey(fileGuid).then((res) => {
            if (res.error !== 0) {
              throw res.descr;
            }
            if (!res.descr) {
              throw "Error loading user report key.";
            }
            return res.descr;
          });
        } catch (error) {
          this.showSnackbar({
            text: "Error loading user report key.",
            color: "error",
            timeoutTime: 2000,
          });
          console.error(error);
        }
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.getDownloadUrl);
      this.showSnackbar({
        text: "Copied URL to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    formatLabel(data) {
      // FORMAT LABELS REMOVING NUMBERS, UNDESCORE AND HYPHENS IN DATA
      return data.replace(/[0-9_-]/g, "");
    },
    async downloadCsv() {
      this.loadingButton = true;
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.slotGuid;
      // let datasetWithExt =
      //   this.selectedDataset !== "All (zip)"
      //     ? this.selectedDataset + ".csv"
      //     : "";
      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        guidFile: guidFile,
        type: "csvzip",
        viewableGuid: viewableGuid,
        // entryname: datasetWithExt.toLowerCase(),
      };
      let viewName = "";
      if (th.fileTypeNumber == 7 || th.fileTypeNumber == 8) {
        viewName = "_" + sanitizefilename(th.selectedView.name);
      }

      //call Api
      return axios
        .get("api/vcad/file/getFile", defs)
        .then((data) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );
          this.downloadDataset(fileURL, viewName);
        })
        .then(() => {
          return (this.loadingButton = false); //return false to loading button in App.vue
        })
        .catch((error) => {
          console.error("Error occurred during download:", error);
          this.showSnackbar({
            text: "Error occurred during download",
            color: "error",
            timeoutTime: 4000,
          });
          this.loadingButton = false;
        });
    },
    downloadDataset(fileURL, viewName) {
      let fileName;
      // if (this.selectedDataset == "All") {
        //file name + extension (.zip)
        fileName =
          this.vcadItem.name.substring(0, this.vcadItem.name.lastIndexOf(".")) +
          viewName +
          ".zip";
      // } else {
        //dataset + extension (.csv)
      //   fileName = datasetWithExt.toLowerCase();
      // }
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    async svfVersExists() {
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.slotGuid;
      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));

      defs["params"] = {
        guidFile: guidFile,
        type: "csvzip",
        viewableGuid: viewableGuid,
        entryname: "10_svfVers.csv",
      };

      //call Api
      return axios.get("api/vcad/file/fileExists", defs).then((data) => {
        if (data.data == false) {
          let datasetToRemove = this.dataset.indexOf("10_svfVers");

          if (datasetToRemove !== -1) {
            this.dataset.splice(datasetToRemove, 1);
          }
        }
      });
    },
  },
  mounted() {
    // this.dataset = vcadDataset(this.fileTypeNumber);
    this.setKey();
    this.getBaseUrl();
    this.svfVersExists();
  },
};
</script>

<style lang="css" scoped>
</style>