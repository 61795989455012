
// const loginTypes = [
//     "vcad",
//     "bim360",
//     "onPremise"
// ];

// import axios from "axios";

const namespaced = false;

const state = {
    isLogged: false,
    loginType: null,
    userFunctionality: [] 
};

const getters = {
    isLogged: state => {
        return state.isLogged;
    },
    userFunctionality: state => {
        return state.userFunctionality;
    },
    /**
     * Check if the user has access to dev information.
     */
    canViewDevInfo: state => {
        return state.userFunctionality.some(func => func.code == 'DEV_INFO');
    },
    /**
     * Check if the user has access to dev information.
     */
    canExportFileInfo: state => {
        return state.userFunctionality.some(func => func.code == 'EXPORT_FILE_INFO');
    },
    /**
     * Check if the user has access to slot manager.
     */
    hasSLotManager: state => {
        return state.userFunctionality.some(func => func.code == 'SLOT_MANAGER');
    }
};

const mutations = {
    SET_IS_LOGGED(state, payload) {
        state.isLogged = payload;
    },
    SET_USER_FUNCTIONALITY(state, payload){
        state.userFunctionality = payload;
    }
};

const actions = {
    /**
     * Set current login state.
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {Boolean} isLogged Determines if the user is logged
     */
    setLoggedState(context, isLogged){
        context.commit('SET_IS_LOGGED', isLogged);
    },
};

const authModule = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};

export default authModule;
