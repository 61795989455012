var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"calc(100vh - 104px)"}},[_c('v-divider'),_c('div',{staticStyle:{"height":"100%"}},[(_vm.isLogged == true)?_c('split-pane',{staticClass:"hidden-divider",attrs:{"min-percent":13,"default-percent":_vm.folderSplitPercent,"split":"vertical"}},[_c('template',{slot:"paneL"},[_c('v-card',{class:!_vm.$vuetify.theme.dark ? 'white' : 'grey darken-4',staticStyle:{"height":"100%"}},[_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mb-2"},[_vm._v("mdi-folder-outline")]),_c('span',{staticStyle:{"font-size":"150%"}},[_vm._v(" Folders")])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 170px)"},attrs:{"id":"folders-card"}},[(_vm.selectedProject)?_c('folder-tree',{attrs:{"targetProjectId":_vm.startTargetProjectItemId,"targetFolderId":_vm.startTargetFolderItemId},on:{"onFolderSelection":_vm.onFolderSelection}}):_vm._e()],1)],1)],1),_c('template',{slot:"paneR"},[_c('split-pane',{attrs:{"min-percent":22,"default-percent":70,"split":"vertical"},on:{"resize":_vm.mainPanelVertResize}},[_c('template',{slot:"paneL"},[_c('split-pane',{ref:"fileVersionSplitPanel",attrs:{"min-percent":35,"default-percent":70,"split":"horizontal"},on:{"resize":_vm.mainPanelHorizResize}},[_c('template',{slot:"paneL"},[_c('v-card',{ref:"fileCard",staticStyle:{"height":"100%"}},[_c('v-card-subtitle',{ref:"fileCardSubtitle",staticClass:"font-weight-bold"},[_c('div',{staticClass:"d-inline-flex align-center justify-space-between",attrs:{"id":"file-subtitle-wrapper"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.selected ? 'secondary' : ''}},[_vm._v("mdi-file")]),_c('h2',{staticStyle:{"padding-left":"4px"}},[_vm._v("Files")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.loadAndSetFileItems(_vm.selected)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-reload")])],1)]}}],null,false,1312019136)},[_c('span',[_vm._v("Reload file list")])])],1),_c('div',{staticClass:"d-flex"},[(_vm.enableDownloadFiles && _vm.selected)?_c('download-action-button',{attrs:{"filesItem":_vm.selectedAllItem}}):_vm._e(),(_vm.selected)?_c('vcad-multi-model-dialog',{attrs:{"folderFiles":_vm.fileItems,"folderName":_vm.activeFolders[0].text,"folderId":_vm.selected.id},scopedSlots:_vm._u([{key:"fedDialogActivator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"small":"","outlined":"","elevation":"2","max-width":"12em","color":"primary","disabled":_vm.mmFiles.length <= 0}},on),[_c('v-icon',[_vm._v("mdi-hexagon-multiple")]),_vm._v(" Federations ")],1)]}}],null,false,3233244776)}):_vm._e(),(_vm.selected)?_c('multi-file-action-dialog',{attrs:{"fileList":_vm.fileItems}}):_vm._e(),(_vm.enableProfilesVcad && _vm.selected)?_c('filter-profile-dialog',{attrs:{"fileList":_vm.fileItems},scopedSlots:_vm._u([{key:"filterDialogActivator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"small":"","outlined":"","elevation":"2","max-width":"12em","color":"primary"}},on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter profiles ")],1)]}}],null,false,1564427350)}):_vm._e()],1),(!_vm.selected)?_c('div',{staticClass:"text-center"},[_vm._v(" Select a folder to see files ")]):_vm._e()])]),(_vm.selected)?_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"margin":"0px","padding-bottom":"5px !important"}},[_c('v-text-field',{staticClass:"shrink mx-2",attrs:{"label":"Search","append-icon":"mdi-magnify","color":"primary","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.fileTableSearchStr),callback:function ($$v) {_vm.fileTableSearchStr=$$v},expression:"fileTableSearchStr"}}),(_vm.selected)?_c('v-btn-toggle',{staticStyle:{"height":"28px"},attrs:{"mandatory":"","color":"primary","group":"","dense":""},model:{value:(_vm.buttonsView),callback:function ($$v) {_vm.buttonsView=$$v},expression:"buttonsView"}},[_c('v-btn',{staticClass:"ma-0 vcad-btn",staticStyle:{"border":"0px"},attrs:{"value":1,"text":"","small":"","outlined":""},on:{"click":function($event){return _vm.manageFileVisualization(false)}}},[_c('v-icon',[_vm._v("mdi-view-headline")])],1),_c('v-btn',{staticClass:"ma-0 vcad-btn",staticStyle:{"border":"0px"},attrs:{"value":2,"text":"","small":"","outlined":""},on:{"click":function($event){return _vm.manageFileVisualization(true)}}},[_c('v-icon',[_vm._v("mdi-view-module")])],1)],1):_vm._e()],1):_vm._e(),(_vm.selected)?_c('v-card-text',{staticStyle:{"padding-top":"0 !important"}},[(!_vm.manageVisualizationFiles)?_c('v-data-table',{staticClass:"vcad-table",staticStyle:{"border-top":"0 !important"},attrs:{"headers":_vm.headersFile,"id":"doc-explorer-main-table","items":_vm.fileItems,"search":_vm.fileTableSearchStr,"disable-pagination":false,"hide-default-footer":false,"footer-props":{
                        'items-per-page-options': [50, 100, 200, -1],
                      },"item-key":"id","single-select":"","dense":"","height":_vm.fileSplitPanelHeight - 56 - 74,"fixed-header":"","color":"primary","loading":_vm.loadingItems},on:{"click:row":_vm.selectItemRow},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Select a folder with at least one file. ")]},proxy:true},{key:"loading",fn:function(){return [_c('v-alert',{staticClass:"mt-7 mx-auto",attrs:{"dense":"","text":"","outlined":"","type":"info","width":"400px","color":"primary"}},[_vm._v("Loading Files...")])]},proxy:true},{key:"item.text",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center",style:({
                            maxWidth:
                              _vm.$vuetify.breakpoint.name == 'xl'
                                ? '409px'
                                : '300px',
                          })},[(
                              item.itemState.processState !=
                              _vm.b360ElementStatusComplete
                            )?_c('v-progress-circular',{attrs:{"size":"24","color":"primary","indeterminate":""}}):_vm._e(),(_vm.enableDownloadFiles)?_c('v-checkbox',{attrs:{"x-small":""},on:{"click":function($event){return _vm.addItem(item)}}}):_vm._e(),_c('v-img',{staticClass:"mr-2",attrs:{"contain":"","max-height":"20","max-width":"20","src":_vm.getFileIconExt(item.fileType)}}),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-inline-block text-truncate",class:_vm.tableItemClass},on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"120"}},[_vm._v(" "+_vm._s(item.text)+" ")])]),_c('v-spacer'),(_vm.enableDownloadFiles)?_c('file-action-menu',{attrs:{"fileItem":item}}):_vm._e()],1)]}},{key:"item.vcadImportStatus",fn:function(ref){
                            var item = ref.item;
return [(item && _vm.selectedProject)?_c('vcad-item-status',{attrs:{"item":_vm.excludeBase64Object(item),"disabledActions":_vm.loadingVersions ||
                            _vm.loadingItems ||
                            item.itemState.processState !=
                              _vm.b360ElementStatusComplete,"relativeFileId":item.id,"relativeFileType":item.type,"relativeProjectId":_vm.selectedProject.id},on:{"selected-status":function($event){return _vm.selectTableRow(item, _vm.selectedFile, true)}}}):_vm._e()]}},{key:"item.version",fn:function(ref){
                              var item = ref.item;
return [_c('version-selector',{attrs:{"item":item}})]}},{key:"item.lastUpdateUserName",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(_vm._s(item.lastUpdateUserName))])]}},{key:"item.vcadItem.dateEndConv",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(" "+_vm._s(_vm.slotDateMap[item.derivativeUrn] ? _vm.slotDateMap[item.derivativeUrn] : "Not imported")+" ")])]}},{key:"item.storageSize",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSize(item.storageSize))+" ")]}},{key:"item.convTypeLabel",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(" "+_vm._s(item.vcadItem ? _vm.getFileConfigLabelMap[item.vcadItem.type] : "")+" ")])]}}],null,true),model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}):_vm._e(),(_vm.manageVisualizationFiles)?_c('v-data-iterator',{staticClass:"position:fixed;bottom:0",attrs:{"loading":_vm.loadingItems,"items":_vm.fileItems,"disable-pagination":false,"hide-default-footer":false,"search":_vm.fileTableSearchStr,"max-height":_vm.fileSplitPanelHeight,"fixed-header":"","page":_vm.page,"dense":"","height":_vm.versionSplitPanelHeight - 8,"footer-props":{
                        'items-per-page-options': [10, 20, 50, -1],
                      },"items-per-page":_vm.itemsPerPage,"color":"primary"},on:{"click:row":_vm.selectItemRow,"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-alert',{staticClass:"mt-7 mx-auto",attrs:{"dense":"","text":"","outlined":"","type":"info","width":"400px","color":"primary"}},[_vm._v("Loading Files...")])]},proxy:true},{key:"default",fn:function(props){return [_c('v-responsive',{staticClass:"overflow-y-auto",attrs:{"max-height":_vm.fileSplitPanelHeight - 50}},[_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.text,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{on:{"click":function($event){return _vm.selectItemRow(item, null)}}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":item.imageBase64,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"150px"}},[_c('v-app-bar',{staticClass:"mb-5",attrs:{"flat":"","color":"rgba(0, 0, 0, 0)","dense":""}},[(_vm.enableDownloadFiles)?_c('v-checkbox',{staticClass:"mt-5",attrs:{"x-small":""},on:{"click":function($event){return _vm.addItem(item)}}}):_vm._e(),_c('v-img',{staticClass:"mr-1",attrs:{"max-height":"20","max-width":"20","src":_vm.getFileIconExt(item.fileType)}}),_c('v-spacer'),_c('version-selector',{staticClass:"ml-2",attrs:{"item":item}})],1),_c('v-card-title',{staticClass:"text-left"},[_c('span',{staticClass:"d-inline-block text-truncate"},[_vm._v(_vm._s(item.text))])]),_c('v-card-subtitle',[_c('span',{staticClass:"text-caption mr-1",staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.lastUpdateUserName))])])],1),_c('v-card-actions',[_c('v-spacer'),(item)?_c('vcad-item-status',{attrs:{"item":_vm.excludeBase64Object(item),"disabledActions":_vm.loadingVersions ||
                                      _vm.loadingItems ||
                                      item.itemState.processState !=
                                        _vm.b360ElementStatusComplete,"relativeFileId":item.id,"relativeFileType":item.type,"relativeProjectId":_vm.selectedProject.id},on:{"selected-status":function($event){return _vm.selectTableRow(item, _vm.selectedFile, true)}}}):_vm._e()],1)],1)],1)}),1)],1)]}}],null,false,2131942503),model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}):_vm._e()],1):_vm._e()],1)],1),_c('template',{slot:"paneR"},[_c('v-card',{ref:"versionCard",staticStyle:{"height":"100%"}},[_c('v-card-subtitle',{ref:"versionCardSubtitle",staticClass:"font-weight-bold vcad-main-section-sbutitle"},[_c('div',{staticClass:"d-inline-flex align-center justify-space-between",attrs:{"id":"file-subtitle-wrapper"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.selectedFileItem ? 'secondary' : ''}},[_vm._v("mdi-cards")]),_c('h2',{staticStyle:{"padding-left":"4px"}},[_vm._v("Versions")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.loadAndSetVersions(_vm.selectedFile[0])}}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,false,3400620424)},[_c('span',[_vm._v("Reload version list")])])],1),(!_vm.selectedFileItem)?_c('div',{staticClass:"text-center"},[_vm._v(" Select a file to see versions ")]):_vm._e()])]),(_vm.selectedFileItem)?_c('v-card-text',[_c('v-data-table',{staticClass:"vcad-table",attrs:{"headers":_vm.headersVersion,"disable-pagination":"","hide-default-footer":"","items":_vm.versionItems,"single-select":"","dense":"","item-key":"id","height":_vm.versionSplitPanelHeight - 24,"fixed-header":"","loading":_vm.loadingVersions},on:{"click:row":_vm.selectVersionRow,"current-items":_vm.versionCurrentItems},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-alert',{staticClass:"mt-7 mx-auto",attrs:{"dense":"","text":"","outlined":"","type":"info","width":"400px","color":"primary"}},[_vm._v("Loading Versions...")])]},proxy:true},{key:"item.version",fn:function(ref){
                                        var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center",staticStyle:{"max-width":"19em"}},[(
                              item.itemState.processState !=
                              _vm.b360ElementStatusComplete
                            )?_c('v-progress-circular',{attrs:{"size":"24","color":"primary","indeterminate":""}}):_vm._e(),_c('v-icon',[_vm._v(_vm._s(_vm.getFileIcon(item.fileType)))]),_c('span',{staticClass:"font-weight-bold",class:_vm.tableItemClass},[_vm._v(" "+_vm._s("V" + item.revisionDisplayLabel))]),(_vm.enableDownloadFiles)?_c('file-action-menu',{attrs:{"fileItem":item}}):_vm._e()],1)]}},{key:"item.vcadImportStatus",fn:function(ref){
                            var item = ref.item;
return [_c('vcad-item-status',{attrs:{"item":_vm.excludeBase64Object(item),"disabledActions":_vm.loadingVersions ||
                            _vm.loadingItems ||
                            item.itemState.processState !=
                              _vm.b360ElementStatusComplete,"relativeFileId":_vm.selectedFileItem.id,"relativeFileType":_vm.selectedFileItem.type,"relativeProjectId":_vm.selectedProject.id},on:{"selected-status":function($event){return _vm.selectTableRow(item, _vm.selectedVersion)}}})]}},{key:"item.lastUpdateUserName",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(_vm._s(item.lastUpdateUserName))])]}},{key:"item.vcadItem.dateEndConv",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(_vm._s(_vm.slotDateMap[item.derivativeUrn] ? _vm.slotDateMap[item.derivativeUrn] : "Not imported"))])]}},{key:"item.storageSize",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(_vm._s(_vm.getSize(item.storageSize)))])]}},{key:"item.convTypeLabel",fn:function(ref){
                              var item = ref.item;
return [_c('span',{class:_vm.tableItemClass},[_vm._v(" "+_vm._s(item.vcadItem ? _vm.getFileConfigLabelMap[item.vcadItem.type] : "")+" ")])]}}],null,true),model:{value:(_vm.selectedVersion),callback:function ($$v) {_vm.selectedVersion=$$v},expression:"selectedVersion"}})],1):_vm._e()],1)],1)],2)],1),_c('template',{slot:"paneR"},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-subtitle',{ref:"fileCardSubtitle",staticClass:"font-weight-bold"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.currentForgeFileData !== null &&
                        _vm.currentForgeFileData.b360Item !== null
                          ? 'secondary'
                          : ''}},[_vm._v("mdi-information")]),_c('h2',{staticStyle:{"padding-left":"4px"}},[_vm._v("Info")])],1),(
                      _vm.currentForgeFileData == null ||
                      _vm.currentForgeFileData.b360Item == null
                    )?_c('div',[_vm._v(" Select a file to see info ")]):_vm._e()])]),(
                  _vm.currentForgeFileData !== null &&
                  _vm.currentForgeFileData.b360Item !== null
                )?_c('file-info',{ref:"fileInfoComp",staticStyle:{"overflow":"hidden"}}):_vm._e()],1)],1)],2)],1)],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }