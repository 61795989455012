<template>
  <vcad-dialog
    v-model="multiFileActionDialog"
    title="Actions"
    dialogWidth="1000"
  >
    <template v-slot:dialogActivator="{ on }">
      <v-btn
        v-on="on"
        outlined
        small
        elevation="2"
        color="primary"
        class="vcad-btn file-action-btn"
        :disabled="fileList.length <= 0"
        max-width="12em"
      >
        <v-icon>mdi-tune</v-icon>
        Actions
      </v-btn>
    </template>
    <template v-slot:content>
      <multi-file-action
        :fileList="fileList"
        @warning="warningHandler"
      ></multi-file-action>
    </template>

    <!-- ACTIONS -->
    <template v-slot:actions>
      <div
        class="d-inline-flex align-center px-4"
        style="width: 100%; height: 100%"
      >
        <v-alert
          type="warning"
          border="left"
          outlined
          text
          dense
          class="mt-3"
          v-if="warningList.length > 0"
        >
          <template v-for="warning in warningList">
            <p :key="warning.type"><b>WARNING:</b> {{ warning.msg }}</p>
          </template>
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          color=""
          class="vcad-btn"
          @click="multiFileActionDialog = false"
          >CLOSE</v-btn
        >
      </div>
    </template>
  </vcad-dialog>
</template>

<script>
import VcadDialog from "../VcadDialog";
import MultiFileAction from "../MultiFileAction";
export default {
  props: {
    fileList: {
      required: true,
    },
  },
  components: {
    VcadDialog,
    MultiFileAction,
  },
  data() {
    return {
      multiFileActionDialog: false,
      warningList: [],
    };
  },
  methods: {
    /**
     * Update the warninig message.
     * @param {String} msg Warning message.
     * @param {String} type Type of  warning. Possible values ['import-warning', 'delete-warning']
     */
    warningHandler(msg, type) {
      // Create filtered copy of current warnings
      let newWarningList = this.warningList.filter((warn) => warn.type != type);
      // If new warning add to list
      if (msg) {
        newWarningList.push({ type: type, msg: msg });
      }
      // Update warning list
      this.warningList = newWarningList;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>