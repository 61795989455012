<template>
  <v-app v-resize="windowResize">
    <!-- LOGIN -->
    <login v-if="!isLogged"></login>

    <v-app-bar
      absolute
      :dark="setDark"
      :src="imagesAppBar"
      app
      color="primary"
      v-if="isLogged"
    >
      <div class="d-flex align-center" id="app-logo">
        <v-img
          alt="Vcad Logo"
          class="shrink mr-3"
          contain
          src="@/assets/vcad_logo.png"
          transition="scale-transition"
          height="50"
          width="150"
        />
      </div>

      <!-- ACCOUNT SELECT -->
      <account-selector v-if="isLogged"></account-selector>

      <v-divider vertical dark class="ml-2 mr-3" v-if="isLogged"></v-divider>

      <!-- APP MENU -->
      <app-menu v-if="isLogged" class="flex-grow-1"></app-menu>

      <!-- VCAD CONFIGURATION -->
      <!-- <vcad-configuration
        v-model="showConfigurationDialog"
        title="Settings"
        dialogWidth="900"
      >
      </vcad-configuration> -->

      <!-- USER PROFILE INFO -->
      <user-profile @sign-out="logOut" v-if="userProfile"></user-profile>
    </v-app-bar>

    <!-- APP CONTENT -->
    <v-main v-if="isLogged">
      <router-view
        :class="!$vuetify.theme.dark ? 'grey lighten-5' : 'black'"
      ></router-view>
    </v-main>

    <v-footer v-if="isLogged == true && slotCount > -1">
      <v-chip
        class="mr-1 ml-1"
        :color="slotUsedCount == slotCount ? 'warning' : 'primary'"
        text-color="white"
        small
      >
        Slots: {{ slotUsedCount + "/" + slotCount }}
      </v-chip>
      <v-chip
        class="mr-1 ml-1"
        color="primary"
        text-color="white"
        small
        v-if="numImportingFiles > 0"
      >
        Importing: {{ numImportingFiles }} files
      </v-chip>
      <v-chip
        class="mr-1 ml-1"
        :color="subscriptionStatus == 'active' ? 'warning' : 'error'"
        text-color="white"
        small
        v-if="trialMode == 1"
      >
        Trial Mode (expires on: {{ vcadExpirationDate }})
      </v-chip>
      <v-chip
        class="mr-1 ml-1"
        :color="expirationDateColor"
        text-color="white"
        small
        v-if="trialMode !== 1"
      >
        Expires on: {{ vcadExpirationDate }}
      </v-chip>

      <v-spacer></v-spacer>
      <v-btn
        text
        class="mr-3"
        dense
        style="height: 24px"
        href="https://www.bimservices.it/personal-data/"
        target="_blank"
        >Privacy Policy</v-btn
      >
      Blogic ©{{ new Date().getFullYear() }}
    </v-footer>

    <iframe
      ref="hiddenFrame"
      id="hiddenFrame"
      @load="logOutIframeLoadEvent"
      style="visibility: hidden; display: none"
    />

    <!-- MODEL_PREVIEW -->
    <v-dialog
      eager
      persistent
      v-model="showPreview"
      :fullscreen="resizePreview"
    >
      <v-card class="d-flex flex-column" style="height: 85vh">
        <!-- <v-card-subtitle>{{
          currentForgeFileData !== null &&
          currentForgeFileData.b360Item !== null
            ? currentForgeFileData.b360Item.text
            : ""
        }}</v-card-subtitle> -->
        <v-card-title class="px-0 py-0 mb-3">
          <v-toolbar dense flat dark color="primary">
            <v-app-bar-nav-icon :disabled="true">
              <v-img
                v-if="
                  currentForgeFileData !== null &&
                  currentForgeFileData.b360Item !== null
                "
                max-height="20"
                max-width="20"
                :src="dialogIcon"
              ></v-img></v-app-bar-nav-icon
            ><v-toolbar-title>{{
              currentForgeFileData !== null &&
              currentForgeFileData.b360Item !== null
                ? currentForgeFileData.b360Item.text.split(".")[0]
                : ""
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="setSizePreview()">
              <v-icon v-if="!resizePreview">mdi-window-maximize</v-icon>
              <v-icon v-else>mdi-dock-window</v-icon>
            </v-btn>
            <v-btn icon @click="closePreview()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text dense>
          <model-preview></model-preview>
        </v-card-text>
        <v-spacer></v-spacer>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="setShowPreview({ show: false, mode: 0 })">
            Close
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <!-- TEMPLATE DOWNLOAD -->
    <vcad-dialog
      :iconImg="dialogIcon"
      v-model="showTemplateDialog"
      title="Templates"
      dialogWidth="1100"
    >
     <template v-slot:top>
       <v-btn  small icon :href="getDocLink()" target="_blank">
      <v-icon>mdi-information</v-icon>
    </v-btn>
        
      </template>
      <template v-slot:content>
        <vcad-template ref="vcadTemplate"></vcad-template>
      </template>
      <template v-slot:actions>
        <div class="d-inline-flex justify-end mb-4" style="width: 100%">
          <!-- <v-btn
            outlined
            small
            @click="downloadCsvTemplate()"
            color="primary"
            class="vcad-btn ml-4"
            :loading="loadingButton"
            ><v-icon class="mr-1">mdi-download-box</v-icon>Csv</v-btn
          > -->
          <v-spacer></v-spacer>
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn mr-4"
            @click.stop="showTemplateDialog = false"
            >CLOSE</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
    <!-- <v-dialog max-width="60%" @input="setShowTemplates" :value="showTemplates">
      <v-card>
        <v-card-subtitle> Templates </v-card-subtitle>
        <v-card-text>
          <vcad-template></vcad-template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="setShowTemplates(false)"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog
      max-width="60%"
      :value="axiosErrorState.state == 1 && axiosErrorState.isBlocking == true"
    >
      <v-card>
        <v-card-subtitle> Error {{ axiosErrorState.errorNr }} </v-card-subtitle>
        <v-card-text>
          {{ axiosErrorState.message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click.stop="
              clearAxiorErrorState();
              logOut();
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-snackbar
      style="z-index: 999"
      bottom
      absolute
      color="teal darken-4"
      :value="getSnackBarVisibility"
      :timeout="-1"
    >
      {{ axiosErrorState.message }}
      <v-icon color="white" text @click="clearAxiorErrorState()"
        >mdi-close-box-outline</v-icon
      >
    </v-snackbar> -->

    <vcad-snackbar
      :value="snackbarIsVisible"
      :text="snackbarInfo.text"
      :customTimeout="snackbarInfo.timeoutTime"
      :customColor="snackbarInfo.color"
      @closeSnack="snackbarInfo.onCloseFunc()"
    ></vcad-snackbar>
  </v-app>
</template>

<script>
import ModelPreview from "./components/ModelPreview";
import VcadTemplate from "./components/VcadTemplate";
import UserProfile from "./components/UserProfile";
import AccountSelector from "./components/AccountSelector";
import AppMenu from "./components/AppMenu";
import { mapGetters, mapActions } from "vuex";
import { formattedDate } from "./utils/FormattedDate"; 
import axios from "axios";
import EventBus from "./event-bus";
import { errorManager } from "./utils/AxiosErrorHandler";
import { getFileIconExt } from "./utils/GetFileIconExtension";
import Login from "./components/Login";
import VcadDialog from "./components/VcadDialog";
import VcadSnackbar from "./components/VcadSnackbar";
import { vcadDocsLink } from "./utils/VCadItemStatusUtil";

// import VcadConfiguration from "./components/VcadConfiguration";

//import loadjs from "loadjs";
export default {
  name: "App",

  components: {
    ModelPreview,
    VcadTemplate,
    UserProfile,
    AccountSelector,
    AppMenu,
    Login,
    VcadDialog,
    VcadSnackbar,
    // VcadConfiguration,
  },
  // created() {
  //   let th = this;

  //   axios.interceptors.response.use(
  //     function (response) {
  //       return response;
  //     },
  //     function (error) {
  //       errorManager(error);
  //       return Promise.reject(error);
  //     }
  //   );

  //   try {
  //     axios
  //       .get("api/forge/oauth/token", this.axiosSettings)
  //       .then(function (data) {
  //         // th.$store.commit("setIsLogged", true);
  //         th.setLoggedState(true);
  //         th.setForgeToken(data.data);
  //         th.setHub()
  //           .then(async () =>{
  //             let fromcookie = null;
  //             let sp = th.$cookies.get("user_selectedProject");
  //             if (sp) {
  //                 fromcookie = th.hubList.find(h => h.id == sp.accountId);
  //             }
  //             return await th.setCurrentHub(fromcookie ? fromcookie : th.hubList[0]);
  //           })
  //           .then(() => {
  //             // Load project based on ruote
  //             const projectFromUrl = th.$router.currentRoute.params.startTargetProjectItemId;
  //             th.setSelectedProjectByItemId(projectFromUrl);
  //             console.log(projectFromUrl)
  //             console.log('Test...')
  //           })
  //           .then(() => {
  //             th.setVcadFileList();
  //           })
  //           .catch((err) => {
  //             if (err == "no-valid-account") {
  //               console.warn("No valid account for the user.");
  //             } else {
  //               console.error(err);
  //             }
  //           });
  //       })
  //       .catch();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  async created() {
    let th = this;

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        errorManager(error);
        return Promise.reject(error);
      }
    );

    try {
      const data = await axios.get("api/forge/oauth/token", this.axiosSettings);
      th.setLoggedState(true);
      th.setForgeToken(data.data);
      await th.setHub();
      // Set current hub
      let fromcookie = null;
      let sp = th.$cookies.get("user_selectedProject");
      if (sp) {
        fromcookie = th.hubList.find((h) => h.id == sp.accountId);
      }
      await th.setCurrentHub(fromcookie ? fromcookie : th.hubList[0]);

      // Load projects
      await th.loadProjects();

      // Set current project
      const projectFromUrl =
        th.$router.currentRoute.params.startTargetProjectItemId;
      if (projectFromUrl) {
        await th.setSelectedProjectByItemId(projectFromUrl);
      } else {
        let sp = th.$cookies.get("user_selectedProject");
        let fromcookie;
        if (sp) {
          fromcookie = th.projects.find((h) => {
            return h.id == sp.projectId;
          });
        }
        await th.setSelectedProject(
          fromcookie != null ? fromcookie : th.projects[0]
        );
      }

      // Set file list
      th.setVcadFileList();
    } catch (err) {
      if (err == "no-valid-account") {
        console.warn("No valid account for the user.");
      } else {
        console.error(err);
      }
    }
  },

  data() {
    return {
      showChangeAccount: false,
      callSignout: false,
      loadingButton: false,
      showConfigurationDialog: false,
    };
  },

  watch: {
    getSnackBarVisibility(value) {
      if (value == true) {
        // setTimeout(() =>  this.clearAxiorErrorState(), 4000);

        this.showSnackbar({
          text: this.axiosErrorState.message,
          color: "error",
          timeoutTime: -1,
          onCloseFunc: this.clearAxiorErrorState,
        });
      }
    },
    isLogged(value) {
      if (value == true) {
        this.setUserProfile();
      }
    },
    // projects(value) {
    //   if (value) {
    //     let sp = this.$cookies.get("user_selectedProject");
    //     if (sp) {
    //       let obj = value.find((o) => o.id === sp);
    //       if (obj) {
    //         this.setSelectedProject(obj);
    //       }
    //     }
    //   }
    // },
  },

  computed: {
    ...mapGetters([
      "isLogged",
      "projects",
      "selectedProject",
      "showPreview",
      "resizePreview",
      "showTemplates",
      "currentForgeFileData",
      "userProfile",
      "currentHub",
      "hubList",
      "isLoading",
      "axiosSettings",
      "slotCount",
      "vcadFileList",
      "axiosErrorState",
      "slotUsedCount",
      "trialMode",
      "subscriptionStatus",
      "expirationDate",
      "snackbarInfo",
      "snackbarIsVisible",
    ]),
    dialogIcon() {
      const iconExt = this.currentForgeFileData?.b360Item?.text.split(".")[1];
      const icon = getFileIconExt(iconExt);
      return icon;
    },
    setDark() {
      if (localStorage.setPrimaryColorAppBar) {
        if (!(localStorage.setPrimaryColorAppBar.toLowerCase() === "true")) {
          //if the primary color is not setted
          if (this.$vuetify.theme.dark) {
            //otherwise set the 'dark' property of app bar respect to the dark/light mode
            return true;
          } else {
            return false;
          }
        } else {
          return true; //set always 'dark' property of app bar to false
        }
      } else {
        if (this.$vuetify.theme.dark) {
          //if the primary color is  setted
          //otherwise set the 'dark' property of app bar respect to the dark/light mode
          return true;
        } else {
          return false;
        }
      }
    },

    imagesAppBar() {
      var images = require.context("./assets/", false, /\.png$/);
      let url = "";
      const usePrimaryColor = localStorage?.setPrimaryColorAppBar?.toLowerCase() === "true" 
      if (usePrimaryColor) {
        url = null
      } else {
        url = this.$vuetify.theme.dark ? images("./background-2.png") : images("./background-1.png");
      }
      return url;
    },

    getSnackBarVisibility() {
      return (
        this.axiosErrorState.state == 1 &&
        this.axiosErrorState.isBlocking == false
      );
    },
    showTemplateDialog: {
      get() {
        return this.showTemplates;
      },
      set(value) {
        this.setShowTemplates(value);
      },
    },
    numImportingFiles() {
      return this.vcadFileList.reduce((prev, curr) => {
        const importingStats = [0, 1];
        return importingStats.includes(curr.status) ? prev + 1 : prev;
      }, 0);
    },
    vcadExpirationDate() {
      return this.expirationDate ? formattedDate(this.expirationDate).split(",")[0] : "No expiration date";
    },
    expirationDateColor() {
      let expirationDate = new Date(this.vcadExpirationDate).getTime();
      let today = new Date().getTime();
      let daysLeft = expirationDate - today;
      let difference = Math.floor(daysLeft / (1000 * 60 * 60 * 24));
      return difference <= 10 ? "red" : "primary";
    },
  },
  methods: {
    ...mapActions([
      "setSelectedProject",
      "setShowPreview",
      "setResizePreview",
      "setShowTemplates",
      "setUserProfile",
      "setCurrentHub",
      "setForgeToken",
      "setHub",
      "setAxiorErrorState",
      "clearAxiorErrorState",
      "setVcadManagedFileTypes",
      "setVcadFileList",
      "setLoggedState",
      "showSnackbar",
      "setSelectedProjectByItemId",
      "loadProjects",
    ]),
    getDocLink() {
      let docLink = vcadDocsLink.find((file) => this.currentForgeFileData.vcadItem?.type == file.type);
      if (!docLink) {
        docLink = vcadDocsLink.find((file) => null == file.type);
      }
      return docLink.link;
    },
    closePreview() {
      this.setShowPreview({ show: false, mode: 0 });
      this.setResizePreview({ resize: false });
    },

    async downloadCsvTemplate() {
      this.loadingButton = true;
      let u = await this.$refs.vcadTemplate.downloadCsvZipViews();
      this.loadingButton = u;
    },

    getAuthUrl() {
      axios
        .get("api/forge/oauth/url", this.axiosSettings)
        .then(function (response) {
          location.href = response.data;
        });
    },

    logOutIframeLoadEvent() {
      //// console.log("logOutIframeLoadEvent");

      if (this.callSignout == true) {
        location.href = this.axiosSettings.baseURL + "api/forge/oauth/signout";
        this.callSignout = false;
      }
    },

    logOut() {
      //// console.log("logOut");
      let frm = this.$refs.hiddenFrame;
      frm.src = "https://accounts.autodesk.com/Authentication/LogOut";
      this.callSignout = true;
    },

    /**
     * Emit window resize event
     */
    windowResize(evt) {
      EventBus.$emit("windowResizeEvent", evt);
    },

    /*
    Method to set the siez eof window of preview:
    * Parameters = None;
    * set variable of v-card's style;
    */

    setSizePreview() {
      this.setResizePreview({ resize: !this.resizePreview });
    },
  },
  mounted() {
    // Get list of supported file types
    this.setVcadManagedFileTypes();
  },
};
</script>
<style lang="css" scoped>
#app-logo {
  padding: 8px;
  margin-right: 5px;
  /* background: rgb(200, 200, 200);
  background: radial-gradient(
    circle,
    rgba(100, 100, 100, 1) 0%,
    rgba(100, 100, 100, 0.5) 75%,
    rgba(100, 100, 100, 0) 100%
  ); */
}
</style>