<template>
  <vcad-dialog
    v-model="showDialog"
    title="Download extracted data"
    dialogWidth="1000"
  >
    <!-- INFO BUTTON -->
    <template v-slot:top>
      <v-btn small icon href="https://www.bimservices.it/new-window-for-downloading-bim-file-data/" target="_blank">
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>

    <!-- DIALOG ACTIVATOR -->
    <template v-slot:dialogActivator="{ on }">
      <v-btn
        v-on="on"
        outlined
        small
        color="primary"
        style="width: 100%"
        class="vcad-btn"
        >Download data</v-btn
      >
    </template>

    <template v-slot:content>
      <v-alert
        v-if="JSON.parse(slotData.info).b360Item.storageSize > 200000000"
        border="left"
        color=""
        type="info"
        >This process may require additional time, especially with larger
        files.</v-alert
      >
      <!-- VIEWS SELECTOR -->
      <v-select
        v-if="visibleView"
        class="flex-grow-0 mb-2 mt-1"
        style="width: 50%"
        label="View"
        v-model="selectedView"
        return-object
        item-text="name"
        item-key="guid"
        :items="vcadCurrentViews"
        hint="Available views and phases"
        append-outer-icon="mdi-content-copy"
        @click:append-outer="copyViewGuid()"
        outlined
        persistent-hint
        dense
      >
        <template v-slot:append>
          <span class="grey--text text-caption" style="margin-top: 2px">
            ( {{ vcadCurrentViews.length }} views)
          </span>
          <v-icon>mdi-menu-down</v-icon>
        </template>
      </v-select>
      <!-- TABS -->
      <v-tabs
        style="border: 1px solid #f0f0f0"
        class=""
        grow
        icons-and-text
        centered
        vertical
      >
        <v-tabs-slider style="height: 100%" color="secondary"></v-tabs-slider>
        <v-tooltip
          v-for="tab in tabs"
          :key="tab.tabName"
          bottom
          :disabled="!tab.isDisabled"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" style="height: 100%" class="d-inline-block">
              <v-tab
                style="
                  border-right: 1px solid #f0f0f0;
                  margin-left: 0;
                  height: 100%;
                "
                class="font-weight-black"
                :disabled="tab.isDisabled"
                dense
                >{{ tab.tabName }}<v-icon>{{ tab.icon }}</v-icon></v-tab
              >
            </div>
          </template>
          <span>{{ tab.tooltipMessage }}</span>
        </v-tooltip>
        <v-tab-item key="CSV" :transition="false">
          <slot-csv-data
            :slotGuid="slotData.id"
            :fileTypeNumber="slotData.type"
            :vcadItem="slotData"
            :selectedView="selectedView"
          ></slot-csv-data>
        </v-tab-item>
        <v-tab-item key="PARQUET" :transition="false">
          <slot-parquet-data
            :slotGuid="slotData.id"
            :fileTypeNumber="slotData.type"
            :vcadItem="slotData"
            :selectedView="selectedView"
          ></slot-parquet-data>
        </v-tab-item>
      </v-tabs>
    </template>
    <!-- TABS ACTIONS -->
    <template v-slot:actions>
      <div class="d-inline-flex justify-end" style="width: 100%; height: 100%">
        <v-btn
          outlined
          small
          color=""
          class="vcad-btn"
          @click="showDialog = false"
          >CLOSE</v-btn
        >
      </div>
    </template>
  </vcad-dialog>
</template>

<script>
import VcadDialog from "../VcadDialog.vue";
import { mapGetters } from "vuex";
import SlotCsvData from "./SlotCsvData.vue";
import SlotParquetData from "./SlotParquetData.vue";
import { mapActions } from "vuex";
import { vcadDataset } from "../../utils/VcadDatasets";
import axios from "axios";

export default {
  props: {
    slotData: {
      required: false,
    },
  },
  components: { "vcad-dialog": VcadDialog, SlotCsvData, SlotParquetData },
  data() {
    return {
      loadingButton: false,
      showDialog: false,
      selectedView: null,
      visibleView: false,
      dataset: [],
      parquetExists: null,
    };
  },
  watch: {
    vcadCurrentViews() {
      this.setDefaultView();
    },
  },
  computed: {
    ...mapGetters(["currentViews", "axiosSettings"]),
    vcadCurrentViews() {
      return this.vcadViewList ? this.vcadViewList : this.currentViews;
    },
    tabs() {
      let tabList = [
        {
          tabName: "CSV",
          tabOrder: "Tab 1",
          icon: "mdi-file-delimited",
          isDisabled: false,
          tooltipMessage: "",
        },
        {
          tabName: "PARQUET",
          tabOrder: "Tab 2",
          icon: "mdi-parking",
          isDisabled: !this.parquetExists,
          tooltipMessage:
            "Parquet available only for slots imported from October 2023",
        },
      ];
      return tabList;
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    copyViewGuid() {
      navigator.clipboard.writeText(this.selectedView.guid);
      this.showSnackbar({
        text: "Copied guid to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    setDefaultView() {
      this.selectedView = null;
      if (this.vcadCurrentViews && this.vcadCurrentViews.length > 1) {
        this.visibleView = true;
      }
      if (this.vcadCurrentViews && this.vcadCurrentViews.length > 0) {
        let defaultView = this.vcadCurrentViews.find((o) => o.name == "{3D}");
        // if (defaultView) {
        //   this.selectedView = defaultView;
        // }
        this.selectedView = defaultView
          ? defaultView
          : this.vcadCurrentViews[0];
      }
    },
    async fileExists() {
      let th = this;
      let datasetWithExt;
      // *If the file type is not IFC remove numbers, underscores and hyphens. Consider moving this operation to the server-side for consistency.
      if (this.slotData.type !== 11) {
        datasetWithExt = this.dataset[0]?.replace(/[0-9_-]/g, "") + ".parquet";
      } else {
        datasetWithExt = this.dataset[0] + ".parquet";
      }
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));
      defs["params"] = {
        guidFile: th.slotData.id,
        type: "parquet",
        viewableGuid: th.selectedView?.guid,
        entryname: datasetWithExt,
      };
      return axios.get("api/vcad/file/fileExists", defs).then((res) => {
        this.parquetExists = res.data;
      });
    },
  },
  mounted() {
    this.setDefaultView();
    this.dataset = vcadDataset(this.slotData.type);
    this.fileExists();
  },
};
</script>

<style lang="css" scoped>
</style>