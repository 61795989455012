<template>
  <div style="height: 100%">
    <!-- APP MENU LIST -->
        <!-- :class="{
          'active-menu': menuItem.dialogData.targetPath == $route.path,
        }" -->
    <div class="d-inline-flex align-end app-menu-wrapper">
      <v-btn
        active-class="active-menu"
        v-for="(menuItem, index) in visibleMenuItems"
        :key="'MENU_' + index"
        text
        tile
        class="vcad-menu-btn rounded mx-1"
        :to="menuItem.dialogData.targetPath"
        @click="setAndShowPage({ ...menuItem.dialogData, index: index })"
        :disabled="$route.path.includes(menuItem.dialogData.targetPath)"
        >{{ menuItem.btnData.text }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
  },
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogContentData: {},
      targetPath: "",
      menuItems: [],
    };
  },

  computed: {
    ...mapGetters(["trialMode", "hasSLotManager", "vcadUserInfo"]),
    enableMultiModel() {
      try {
        return this.getConfigurationUserVcad.enableFederation;
      } catch (error) {
        return false;
      }
    },
    visibleMenuItems() {
      // General routes
      const genRoutes = [
        {
          btnData: {
            text: "DOCS",
          },
          dialogData: {
            title: "Switch to Document Manager",
            targetView: "Document Manager",
            targetPath: "/b360-doc-manager",
          },
        },
        {
          btnData: {
            text: "SLOTS",
          },
          dialogData: {
            title: "Switch to Slot Manager",
            targetView: "Slot Manager",
            targetPath: "/slot-manager",
          },
        },
      ];

      // Extra routes
      const extraRoutes = [];

      extraRoutes.push({
        btnData: {
          text: "FEDERATIONS",
        },
        dialogData: {
          title: "Switch to Federation Management",
          targetView: "Federation Management",
          targetPath: "/federation-management",
        },
      });

      return [...genRoutes, ...extraRoutes];
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return {};
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
  },
  methods: {
    setAndShowPage({ targetPath }) {
      this.$router.push(targetPath).catch(()=>{console.log();})
      // window.open("/#" + targetPath, "_blank");
    },
  },
  mounted() {},
};
</script>
<style lang="css" scoped>
.app-menu-wrapper {
  height: 100%;
  padding: 0.4em 0;
}
.vcad-menu-btn {
  height: 100% !important;
}
.theme--dark.active-menu {
  border-bottom: solid 2px white;
  color: white !important;
}
.active-menu {
  border-bottom: solid 2px var(--v-primary-base);
}
</style>