<template>
  <!-- VIEWS LIST -->
  <vcad-dialog  v-model="resetViewsDialog" title="Views List" dialogWidth="800">
    <template v-slot:dialogActivator="{ on }">
      <v-btn
        v-on="on"
        outlined
        small
        color="primary"
        style="width: 100%"
        class="vcad-btn"
        >Views List</v-btn
      >
    </template>
    <template v-slot:content="{}">
      <div class="overflow-y-auto elevation-1"  style="height: 50vh">
        <v-data-table
          class="v-table-scrollable"
          :headers="headers"
          :items="currentViews"
          fixed-header
          :show-select="false"
          item-key="guid"
          loading="true"
          dense
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="copyGuidView(item.guid)"
                  >mdi-content-copy</v-icon
                >
              </template>
              <span>Copy View Guid</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </template>

    <!-- ACTIONS -->
    <template v-slot:actions>
      <div class="d-inline-flex justify-end" style="width: 100%; height: 100%">
        <v-btn
          outlined
          small
          color=""
          class="vcad-btn"
          @click="resetViewsDialog = false"
          >CLOSE</v-btn
        >
      </div>
      <vcad-snackbar
        :value="snackbarIsVisible"
        :text="errorConfirmDialog"
        :customTimeout="2000"
        customColor="primary"
        @closeSnack="closeSnack()"
      ></vcad-snackbar>
    </template>
  </vcad-dialog>
</template>

<script>
import VcadDialog from "./VcadDialog";
import { mapGetters } from "vuex";
import VcadSnackbar from "./VcadSnackbar";
export default {
  components: {
    "vcad-dialog": VcadDialog,
    "vcad-snackbar": VcadSnackbar,
  },
  watch: {
    currentViews() {
      this.currentViews.forEach((e) => {
        if (e.name == "{3D}") {
          let u = this.currentViews[0];
          let pos = this.currentViews.indexOf(e);
          this.currentViews[0] = e;
          this.currentViews[pos] = u;
        }
      });
    },
  },
  data() {
    return {
      resetViewsDialog: false,
      snackbarIsVisible: false,
      errorConfirmDialog: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text text-uppercase",
        },
        {
          text: "Type",
          align: "center",
          sortable: false,
          value: "role",
          class: "primary--text text-uppercase",
        },
        {
          text: "Guid",
          align: "center",
          value: "guid",
          class: "primary--text text-uppercase",
        },
        {
          text: "",
          align: "center",
          value: "action",
          class: "primary--text text-uppercase",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentViews"]),
  },
  methods: {
    /*
    Method to copy guid view 
    */

    copyGuidView(guid) {
      let msg = "Guid view copied";
      try {
        navigator.clipboard.writeText(guid);
      } catch (error) {
        msg = "Error during Guid view copy";
      }
      this.errorConfirmDialog = msg;

      this.snackbarIsVisible = true;
    },

    /*
    Method to close snackbar
    */

    closeSnack() {
      this.snackbarIsVisible = false;
    },
  },
};
</script>

<style scoped>
</style>