<template>
  <v-dialog
    persistent
    max-width="40%"
    v-model="dialog" 
    bottom
    min-width="200px"
    rounded
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" x-small v-on="on" @click="loadVersions()">
       {{"V" + item.revisionDisplayLabel }}
      </v-btn>
    </template>
    <v-card>
         <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>Version History</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headersVersion"
          disable-pagination
          hide-default-footer
          :items="versionItems"
          class="elevation-1"
          dense
          show-select        
        >
         
          <template v-slot:[`item.text`]="{ item }">
            {{ item.text }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-btn :disabled="!checkData" color="primary" text @click="prepareAndShowPreview">Compare</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import axios from "axios";

export default {
  props: {
    item: {
      default: null,
    },
       iconColor: {
      default: "#D3D3D3",
    },
  },
  data() {
    return {
      checkData:false,
      dialog: false,
      versionItems: [],
      selected:[],
      headersVersion: [
      
       { text: "Version/Document", value: "text" },
        { text: "", value: "actions" },
        { text: "User Name", value: "lastUpdateUserName" },
        { text: "Date", value: "lastUpdateDate" },
        { text: "Type", value: "type" },
      ],
    };
  },
watch: {
  selected(value) {
    this.checkData = value.length == 2;
  },
  dialog(value){
if (value == true)
{
  this.selected = [];
}
  },
},
  computed: {
    ...mapGetters([
       "axiosSettings",
      "forgeToken",
      "documentTypeName",
      "itemTypeName",
      "versionTypeName",
       "showPreview",
    ]),
  },
  methods: {
    ...mapActions(["fetchNodes","setShowPreview","setFileData"]),
    /**
     * Setup model versions in preview slots, then show the model preview.
     */
    async prepareAndShowPreview(){
      // Load the model data in the slots
      await Promise.all([
        this.setFileData({data:this.selected[0],slot:1}),
        this.setFileData({data:this.selected[1],slot:2})
      ]);
      // Show preview
      this.setShowPreview({show:true,mode:1});
    },
    loadVersions() {
      this.versionItems = [];
      this.fetchNodes({ item: this.item, addChildrenToParent: false })
      .then( (itms) => {
        itms.forEach((node) => {
          this.versionItems.push(node);
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>