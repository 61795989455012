
/**
 * Dictionary of file status.
 */
export const vcadFileStatusDict = {
    // "-1": {txt:"To be Imported",ico:"mdi-file-import-outline",color:"blue" statusId: -1},
    "-1": {txt:"Not imported",ico:"mdi-file-import-outline",color:"info", statusId: -1},
    "0": {txt:"To be processed",ico:"mdi-cloud-upload",color:"black", statusId:0},
    "1": {txt:"Processing",ico:"mdi-sync",color:"info", statusId:1},
    "2": {txt:"Imported",ico:"mdi-swap-horizontal-circle",color:"success", statusId:2},
    "3": {txt:"Error",ico:"mdi-alert",color:"error", statusId:3},
    "-2": {txt: "Not supported", ico:"mdi-alert-box-outline", color:"warning", statusId: -2},
    "-3": {txt: "Broken source", ico:"mdi-image-broken-variant", color:"error", statusId: -3}
}
export const unusableFileStatus = [ -2, -3 ];
export const ActiveFileStatus = [ 2 ];
export const b360ElementStatusComplete = "PROCESSING_COMPLETE";

export const vcadDocsLink = [
    {type: 5, link:"https://www.bimservices.it/vcad-documentation/navisworks-template/"},
    {type: 11, link:"https://www.bimservices.it/vcad-documentation/ifc/"},
    {type: 8, link:"https://www.bimservices.it/vcad-documentation/revit-template/"},
    {type: null, link:"https://www.bimservices.it/vcad-documentation/templates-overview/"}
]
/**
 * Find the matching Vcad file for the provided Autodesk file item.
 * @param {Array<any>} vcadFileList List of user's Vcad Files.
 * @param {any} item Autodesk file item.
 * @returns {any} Matched Vcad file.
 */
export function getFileMatch(vcadFileList, item){
    // Check if Autodesk file can be used
    if(item.itemState && item.itemState.extractionState && item.itemState.extractionState == 'FAILED'){
        return null;
    }
    const matchingFile = vcadFileList.find(vcadFile => {
        const viewableId = vcadFile.info ? JSON.parse(vcadFile.info).viewableGuid : undefined;
        const matchingUrns = vcadFile.forgeUrn == item.derivativeUrn;
        const matchingViewableGuids = !item.viewableGuid || item.viewableGuid == viewableId;
        return matchingUrns && matchingViewableGuids
    });
    return matchingFile;
}

/**
 * Extract file status from Vcad info
 *  and update the vcadItem of provided Forge item.
 * @param {Array<any>} vcadFileList List of Vcad files
 * @param {Obj} item Forge item to match with the Vcad file
 * @param {Array<any>} supportedVcadFileTypes List of supported file types
 */
export function getFileStatus(vcadFileList, item, supportedVcadFileTypes){
    let ret = -1;
    item.vcadItem = null;

    // Check if b360 file can be used
    if(item.itemState && item.itemState.extractionState && item.itemState.extractionState == 'FAILED'){
        ret = -3;
        return ret;
    }

    vcadFileList.forEach((f) => {
        // if (f.info != null) {
        //     let info = JSON.parse(f.info);
        //     if (info.itemId == item.itemId) {
        //         ret = f.status;
        //         item.vcadItem = f;
        //     }
        // }

        //Note:viewableId from vcad == viewableGuid from B360
        if(f.forgeUrn == item.derivativeUrn){
//todo: improve check if viewableguid == null 
            let viewableId = JSON.parse(f.info).viewableGuid;

            if(!item.viewableGuid || item.viewableGuid == viewableId){
                ret = f.status;
                item.vcadItem = f;
            }
        }
    });

    // Check if file is unsupported
    if(ret == -1 && supportedVcadFileTypes){
        const unsupportedStatusId = -2;
        let foundSupportedType = supportedVcadFileTypes.find( type => item.fileType && type.toLowerCase() == item.fileType.replace('.', '').toLowerCase() );
        ret = !foundSupportedType ? unsupportedStatusId : ret;
    }

    return ret;
}



// export default function (vcadFileList, item) {
//     let ret = -1

//     vcadFileList.forEach((f) => {

//         if (f.info != null) {
//             let info = JSON.parse(f.info);
//             if (info.itemId == item.itemId) {
//                 ret = f.status;
//             }
//         }
//     });

//     return ret;
// }