/**
 * Formats the given date time to the user's local format
 * @param {string} dateTime String rapresentation of datetime to format
 * @returns formatted date time string
 */
export function formattedDate(dateTime) {
    if(!dateTime){
        return null;
    }
    const datetimeString = dateTime;
    const date = new Date(datetimeString);
    if(isNaN(date)){
        return date.toString();
    }
    const options = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options).format(date);
    return formattedDate
}
