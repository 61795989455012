import axios from 'axios';
import store from '../store';

export function VcadSlotKey(vcadGuid){
    let apiSettings = JSON.parse(JSON.stringify(store.getters.axiosSettings));
    apiSettings.params = { fileguid: vcadGuid };
    return (axios.get('api/vcad/file/getkeyforvcadfile', apiSettings).then(ret => {
        return ret.data;
    }))
}
