import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import vueTimers from "vue-timers";
import VueCookies from "vue-cookies";
import axios from 'axios';
import router from './router';
import updatesHub from './plugins/updateshub';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

import './assets/css/vcad-styles.css';

Sentry.init({
  dsn: 'https://47d830032f0048e3b60963ffa0dee2f4@sentry.blogic.it/15',
  integrations: [new VueIntegration({Vue: Vue, attachProps: true, logErrors: true})]
});
Vue.config.productionTip = false;

Vue.use(vueTimers);
Vue.use(VueCookies);
Vue.use(updatesHub);

const config = process.env.VUE_APP_CONFIG;
axios.get(config).then(res =>{
  store.commit('SET_BASE_URL', res.data.baseURL);
  store.commit('SET_SIGNALR_BASE_URL', res.data.signalRbaseURL);
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')  
}); 
