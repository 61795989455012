export function vcadDataset(fileTypeNumber) {
    switch (fileTypeNumber) {
        //NAVISWORKS
        case 5:
            return ["04_assets", "05_properties", "01_sourceFiles", "02_objectTypes", "03_objectNames", "09_paths", "10_svfVers"];
        //IFC
        case 11:
            return ["010-header", "020-unit", "030-ifcProject", "040-ifcProjectProperty", "050-ifcSite", "060-ifcSiteProperty", "070-ifcBuilding", "080-ifcBuildingProperty", "090-ifcBuildingStorey", "100-ifcBuildingStoreyProperty", "110-ifcSpace", "120-ifcSpaceProperty", "130-ifcQuantity", "140-ifcMaterial", "150-ifcAsset", "160-ifcAssetProperty"];
        //IFCASNAV
        case 14:
            return ["04_assets", "05_properties", "01_sourceFiles", "02_objectTypes", "03_objectNames", "09_paths", "10_svfVers"];
        //REVIT
        case 8:
            return ["assets", "properties", "levels", "modelproperties", "views", "zextAssets", "zextProperties"];
        //DWG
        case 7:
            return ["04_assets", "05_properties"];
        //INFRAWORKS
        case 13:
            return ["assets", "properties", "views"];
        //DGN
        case 12:
            return ["04_assets", "05_properties"];
        //OBJ
        case 10:
            return ["assets", "properties", "levels", "modelproperties", "views"];
        default:
            return [];

    }

}