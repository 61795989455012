<template>
  <div>
    <h3 class="subtitle pa-2">Manage slots that are no longer active</h3>
    <v-data-table
      v-model="selectedSlots"
      :headers="headers"
      :items="slotList"
      class="elevation-0 vcad-table"
      item-key="Guid"
      fixed-header
      hide-default-footer
      show-select
      selectable-key="isSelectable"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-icon :color="item.iconColor">{{ item.status }}</v-icon>
      </template>
      <!-- TABLE HEADER -->
      <template v-slot:top> </template>
    </v-data-table>
    <!-- {{ JSON.stringify(slotList) }} -->

    <div class="justify-end d-flex pt-4">
      <vcad-dialog v-model="removeSlotsDialog" title="Clear slot">
        <template v-slot:dialogActivator="{ on }">
          <v-btn
            :disabled="selectedSlots.length == 0"
            class="vcad-btn"
            outlined
            v-on="on"
            small
            color="error darken-1"
            >Remove</v-btn
          >
        </template>
        <template v-slot:content>
          <p class="text-center">Are you sure you want to continue?</p>
          <v-alert border="left" outlined text type="warning" dense>
            You are about to remove the selected slots from the federation.
          </v-alert>
        </template>
        <template v-slot:actions>
          <div
            class="d-inline-flex justify-end"
            style="width: 100%; height: 100%"
          >
            <v-btn
              outlined
              small
              color=""
              class="vcad-btn"
              @click="removeSlotsDialog = false"
              >CLOSE</v-btn
            >
            <v-btn
              class="vcad-btn"
              outlined
              small
              color="primary"
              @click="removeSlots"
              >Confirm</v-btn
            >
          </div>
        </template>
      </vcad-dialog>
    </div>

    <!--  -->

    <!-- <v-dialog v-model="showRemoveSlotDialog">
      <v-card>
        <v-card-title class="headline">Slot not found remove</v-card-title>
        <v-card-text>
          {{ this.selectedSlots.length + " will be removed" }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showMMSlotList = false"> Close </v-btn>
          <v-btn text @click="showMMSlotList = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>
 
<script>
import { mapGetters, mapActions } from "vuex";
import VcadDialog from "../VcadDialog.vue";

export default {
  components: {
    "vcad-dialog": VcadDialog,
  },
  mounted() {
    this.selectedSlots = this.slotList.filter((slot) => {
      return slot.isSelectable == true;
    });
  },
  data() {
    return {
      headers: [
        {
          text: "File Name",
          value: "fileName",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Guid",
          value: "Guid",
        },
      ],
      selectedSlots: [],
      removeSlotsDialog: false,
      // showRemoveSlotDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "folderMMProjects",
      "selectedMMProject",
      "selectedFolderMMGUID",
      "templateIdToAdd",
      "mmSlotsNotFound",
      "selectedFolder",
    ]),
    slotList() {
      let ret = [];
      if (this.selectedMMProject) {
        let slots = this.selectedMMProject.slots;
        slots.forEach((slot) => {
          let found =
            this.mmSlotsNotFound.find((f) => f.Guid == slot.Guid) == null;
          let obj = {
            fileName: slot.FileName,
            Guid: slot.Guid,
            isSelectable: !found,
            status: found ? "mdi-check" : "mdi-close",
            iconColor: found ? "green darken-3" : "red darken-3",
          };

          ret.push(obj);
        });
      }
      return ret;
    },
  },

  methods: {
    ...mapActions([
      "saveUpdateMMProject",
      "setMMSlotsNotFound",
      "updSelectedMMPrjSlot",
    ]),
    async removeSlots() {
      // this.selectedSlots.forEach((f) => {
      //   this.selectedMMProject.slots = this.selectedMMProject.slots.filter(
      //     (s) => s.Guid !== f.Guid
      //   );
      //   this.setMMSlotsNotFound(
      //     this.mmSlotsNotFound.filter((s) => s.Guid !== f.Guid)
      //   );
      // });
      this.removeSlotsDialog = false;
      let newSlots = this.selectedMMProject.slots.filter(
        (s) => !this.selectedSlots.some((toRemove) => toRemove.Guid == s.Guid)
      );
      let newNotFound = this.mmSlotsNotFound.filter(
        (s) => !this.selectedSlots.some((toRemove) => toRemove.Guid == s.Guid)
      );
      await this.updSelectedMMPrjSlot(newSlots);
      await this.setMMSlotsNotFound(newNotFound);
      const projectId = this.selectedFolder.projectId;
      const folderItemId = this.selectedFolder.itemId;
      let payload = {
        federationData: this.selectedMMProject,
        projectId: projectId,
        folderItemId: folderItemId,
      };
      await this.saveUpdateMMProject(payload)
        .then((v) => {
          if (v == "ok") {
            this.saveSnackBar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          // TODO: Implement snack bar with error
        });
      this.selectedSlots = [];
      // this.$emit("selectedSlots", this.selectedSlots);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>