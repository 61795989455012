<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col :cols="3">
          <div
            class="elevation-1 pa-5 d-flex flex-column-reverse align-center"
            style="height: 100%"
          >
            <div class="d-flex" style="width: 100%" v-if="!panelIsLoading">
              <v-btn
                class="vcad-btn"
                style="margin: auto"
                @click="showClearDialog = true"
                small
                color="primary"
                outlined
                ><v-icon>mdi-restore</v-icon>Clear</v-btn
              >
              <v-btn
                class="vcad-btn"
                small
                style="margin: auto"
                @click="saveChanges"
                color="primary"
                outlined
                ><v-icon>mdi-content-save</v-icon>Save</v-btn
              >
            </div>
            <v-progress-circular
              indeterminate
              :width="7"
              :size="70"
              color="secondary"
              v-if="panelIsLoading"
              style="
                position: absolute;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
              "
            ></v-progress-circular>
            <!-- v-if="activeTab == 1" -->
            <div
              style="width: 100%"
              class="flex-grow-1"
              ref="col1"
              id="mm-align-panel"
              :class="$vuetify.theme.dark ? 'dark-theme' : 'light-theme'"
            ></div>
          </div>
          <vcad-dialog
            title="Clear alignments"
            v-model="showClearDialog"
            persistent
          >
            <template v-slot:content="{}">
              <p>Are you sure you want to clear all alignments?</p>
            </template>
            <template v-slot:actions="{}">
              <div class="d-inline-flex justify-end mt-2" style="width: 100%">
                <v-btn
                  class="vcad-btn"
                  small
                  outlined
                  @click="showClearDialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  class="vcad-btn"
                  color="primary"
                  small
                  outlined
                  @click="clearAlignments"
                  >Confirm</v-btn
                >
              </div>
            </template>
          </vcad-dialog>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text class="pa-0">
              <div
                id="federation-viewer-main-wrapper"
                class="d-flex justify-start align-center"
              >
                <div
                  style="min-height: 50px"
                  ref="forgeViewerAl"
                  id="forgeViewerAl"
                ></div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <vcad-snackbar
      :value="snackbarIsVisible"
      :text="snackMsg"
      :customTimeout="2000"
      :customColor="colorSnackbar"
      @closeSnack="closeSnack()"
    ></vcad-snackbar>
  </div>
</template>

<script>
//import markupTestData from "../../markupTestData";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import VcadSnackbar from "../VcadSnackbar.vue";
import VcadDialog from "../VcadDialog.vue";
//import custExt from "../../PowerBiTemplateExtension";
//import markupExtension from "../../MarkupExtension";
export default {
  props: {
    show: Boolean,
    activeTab: Number,
  },
  components: {
    VcadSnackbar,
    VcadDialog,
  },
  data() {
    return {
      viewer: null,
      urn: "",
      // viewableId: "",
      lastModelId: 0,
      ls: null,
      previewMode: 0,
      modelAl: null,
      showAligmentMenu: false,
      snackMsg: "",
      snackbarIsVisible: false,
      colorSnackbar: "primary",
      showClearDialog: false,
      isVisible: false,
      panelIsLoading: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.selectedMMProject) {
        let data = {
          multimodelProjectId: this.selectedMMProject.multimodelProjectGuid,
        };

        let th = this;

        th.setMMAlignmentData(data).then(async (d) => {
          th.ls = this.storageMock(d, this.selectedMMProject);
          this.panelIsLoading = true;
          await th.loadViewer();
          this.modelAl = await this.viewer.getExtensionAsync(
            "Autodesk.ModelAlignment"
          );
          let pan = this.modelAl.panel;
          let col = this.$refs.col1;
          pan.parentContainer = col;
          pan.bringToFront();
          if (this.isVisible !== true) {
            pan.setVisible(true);
            this.isVisible = true;
            this.panelIsLoading = false;
          }
        });
      }
    });
  },
  computed: {
    ...mapGetters([
      "axiosSettings",
      "showPreview",
      "forgeToken",
      "selectedMMProject",
      "mmAlignmentData",
    ]),
    canSave() {
      if (this.modelAl) {
        return Object.keys(this.modelAl.pendingChanges).length > 0;
      } else {
        return false;
      }
    },
    urns() {
      let ret = [];
      if (this.selectedMMProject != null) {
        let slots = this.selectedMMProject.slots;
        ret = slots.map((f) => {
          //  return   "urn:" +
          //     (f.b360Item.derivativeUrn === undefined
          //       ? f.urn
          //       : f.b360Item.derivativeUrn);

          return { urn: "urn:" + f.derivativeUrn };
        });
      }
      return ret;
    },

    forgeDivSize() {
      if (this.previewMode == 0) {
        return { height: 97, width: 97 };
      } else {
        return { height: 87, width: 97.5 };
      }
    },
  },
  watch: {
    // async activeTab() {

    // },
    async show(newValue) {
      if (newValue == true) {
        let data = {
          multimodelProjectId: this.selectedMMProject.multimodelProjectGuid,
        };
        let th = this;
        th.setMMAlignmentData(data).then(async (d) => {
          th.ls = this.storageMock(d, this.selectedMMProject);
          this.panelIsLoading = true;
          await th.loadViewer();
          this.modelAl = await this.viewer.getExtensionAsync(
            "Autodesk.ModelAlignment"
          );
          let pan = this.modelAl.panel;
          let col = this.$refs.col1;
          pan.parentContainer = col;
          pan.bringToFront();
          if (this.isVisible !== true) {
            pan.setVisible(true);
            this.isVisible = true;
            this.panelIsLoading = false;
          }
        });
      }
      if (newValue == false) {
        this.modelAl = await this.viewer.getExtensionAsync(
          "Autodesk.ModelAlignment"
        );
        let pan = this.modelAl.panel;
        let col = this.$refs.col1;
        pan.parentContainer = col;
        pan.bringToFront();
        pan.setVisible(false);
        this.isVisible = false;
      }
    },
    // showPreview(newValue) {
    //      this.loadViewer(newValue,false)
    // },
  },
  beforeDestroy() {
    this.viewer.finish();
    this.viewer = null;
    //eslint-disable-next-line
    Autodesk.Viewing.shutdown();
  },
  methods: {
    ...mapActions([
      "setSelectedProject",
      "setShowPreview",
      "setMMAlignmentData",
    ]),
    closeSnack() {
      this.snackbarIsVisible = false;
    },
    saveChanges() {
      this.modelAl = this.viewer.getExtension("Autodesk.ModelAlignment");
      if (Object.keys(this.modelAl.pendingChanges).length > 0) {
        this.modelAl.saveChanges();
        this.snackMsg = "Alignment saved";
        this.colorSnackbar = "primary";
        this.snackbarIsVisible = true;
      }
      // if (this.canSave) {
      // }
    },
    async clearAlignments() {
      let alignmentKeys = Object.keys(this.mmAlignmentData);
      let persistPromises = [];
      const defaultTransform = [
        1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1,
      ];
      await alignmentKeys.forEach(async (key) => {
        let keyParts = key.split("__transform__");
        let derivativeUrn = keyParts[1];
        let slotGuid = null;
        this.selectedMMProject.slots.forEach((slotItem) => {
          if (derivativeUrn == slotItem.derivativeUrn) {
            slotGuid = slotItem.Guid;
          }
        });
        persistPromises.push(
          this.persistAlignment(
            key,
            JSON.stringify({
              transform: defaultTransform,
              isLocked: false,
            }),
            slotGuid,
            this.selectedMMProject.multimodelProjectGuid
          )
        );
      });
      try {
        await Promise.all(persistPromises);
        let data = await this.setMMAlignmentData({
          multimodelProjectId: this.selectedMMProject.multimodelProjectGuid,
        });
        this.ls = this.storageMock(data, this.selectedMMProject);
        this.modelAl = await this.viewer.getExtensionAsync(
          "Autodesk.ModelAlignment"
        );
        let pan = this.modelAl.panel;
        let col = this.$refs.col1;
        pan.parentContainer = col;
        pan.bringToFront();
        pan.setVisible(false);
        this.isVisible = false;
        this.panelIsLoading = true;
        await this.loadViewer();
        this.modelAl = await this.viewer.getExtensionAsync(
          "Autodesk.ModelAlignment"
        );
        pan = this.modelAl.panel;
        col = this.$refs.col1;
        pan.parentContainer = col;
        pan.bringToFront();
        if (this.isVisible !== true) {
          pan.setVisible(true);
          this.isVisible = true;
          this.panelIsLoading = false;
        }
        this.snackbarIsVisible = true;
        this.snackMsg = "Alignments cleared";
        this.colorSnackbar = "primary";
        this.showClearDialog = false;
      } catch (err) {
        console.log(err);
        this.snackbarIsVisible = true;
        this.snackMsg = "Error in clearing alignments.";
        this.colorSnackbar = "error";
      }
    },
    async persistAlignment(key, value, slotGuid, multimodelProjectGuid) {
      let data = {
        key: key,
        slotGuid: slotGuid,
        multimodelProjectGuid: multimodelProjectGuid,
        data: value,
      };

      if (this.selectedMMProject && this.selectedMMProject.alignments) {
        let al = this.selectedMMProject.alignments.find((d) => d.key == key);
        if (al) {
          al.multimodelProjectGuid = data.multimodelProjectGuid;
          al.key = data.key;
          al.slotGuid = data.slotGuid;
          al.data = data.data;
        } else {
          this.selectedMMProject.alignments.push(data);
        }
        //this.$emit("alignment-change",this.selectedMMProject);
      }

      let defs = JSON.parse(JSON.stringify(this.axiosSettings));
      defs.headers["Content-Type"] = "application/json";
      return axios.post("api/vcad/multimodel/savealignmentdata", data, defs);
    },

    getStorageItemAsync(data) {
      let settings = JSON.parse(JSON.stringify(this.axiosSettings));
      settings["params"] = data;
      return new Promise((resolve, reject) => {
        axios.get("api/vcad/multimodel/getalignmentdata", settings).then(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    storageMock(stor, multimodelProject) {
      let storage = stor;
      let urnSlotDict = {};
      multimodelProject.slots.forEach((e) => {
        urnSlotDict[e.derivativeUrn] = e.Guid;
      });

      let th = this;

      return {
        setItem: function (key, value) {
          let slot = th.selectedMMProject.slots.find((e) => {
            return key.includes(e.derivativeUrn);
          });

          storage[key] = value || "";
          th.persistAlignment(
            key,
            value,
            slot ? slot.Guid : "",
            th.selectedMMProject.multimodelProjectGuid
          );
        },
        getItem: function (key) {
          return th.mmAlignmentData[key];
        },
        removeItem: function (key) {
          delete storage[key];
        },
        get length() {
          return Object.keys(storage).length;
        },
        key: function (i) {
          const keys = Object.keys(storage);
          return keys[i] || null;
        },
        getStorage: function () {
          return storage;
        },
      };
    },
    //load viewe (start and resize viewer window)
    loadViewer() {
      const createAlignmentService = () => {
        //eslint-disable-next-line
        const service = new Autodesk.ModelAlignmentService.AlignmentServiceLS(
          this.ls
        );
        return service;
      };

      var options = {
        env: "AutodeskProduction",
        getAccessToken: (callback) => {
          callback(this.forgeToken.access_token, this.forgeToken.expires_in);
        },
      };

      const options3d = {
        viewerConfig: {
          disableBimWalkInfoIcon: true,
          extensions: ["Autodesk.ModelAlignment"],
          theme: this.$vuetify.theme.dark ? "dark-theme" : "light-theme",
        },
        createModelAlignmentService: () => createAlignmentService(),
      };

      let th = this;
      const viewerLoadPromise = new Promise(
        (resolveViewerLoad, rejectViewerLoad) => {
          //eslint-disable-next-line
          Autodesk.Viewing.Initializer(options, async () => {
            //eslint-disable-next-line
            const view = new Autodesk.Viewing.AggregatedView();
            const v = document.getElementById("forgeViewerAl");
            view.init(v, options3d);
            th.viewer = view.viewer;
            // th.viewer.setTheme("light-theme")
            const tasks = [];
            this.urns.forEach((md) => tasks.push(this.loadManifest(md.urn)));

            await Promise.all(tasks)
              .then((docs) =>
                Promise.resolve(
                  docs.map((doc) => {
                    const bubbles = doc
                      .getRoot()
                      .search({ type: "geometry", role: "3d" });
                    const bubble = bubbles[0];
                    if (!bubble) return null;
                    return bubble;
                  })
                )
              )
              .then(async (bubbles) => {
                view.setNodes(bubbles);
                resolveViewerLoad();
              })
              .catch((err) => {
                console.log(err);
                rejectViewerLoad();
              });
          });
        }
      );
      return viewerLoadPromise;
    },

    loadManifest(documentId) {
      return new Promise((resolve, reject) => {
        const onDocumentLoadSuccess = (doc) => {
          doc.downloadAecModelData(() => resolve(doc));
        };
        //eslint-disable-next-line
        Autodesk.Viewing.Document.load(
          documentId,
          onDocumentLoadSuccess,
          reject
        );
      });
    },
  },
};
</script>

<style lang="scss">
/* For extra large screens */
@media screen and (min-width: 1904px) {
  #federation-viewer-main-wrapper > :first-child {
    height: 100% !important;
    width: 50% !important;
  }
  #federation-viewer-main-wrapper {
    height: 82vh !important;
    // width: 98% !important;
  }
}

/* For all other screens */
@media screen and (max-width: 1904px) {
  #federation-viewer-main-wrapper > :first-child {
    height: 100% !important;
    width: 100% !important;
  }
  #federation-viewer-main-wrapper {
    height: 78vh !important;
  }
}

// General panel style
#mm-align-panel #ModelAlignmentPanel {
  overflow: auto;
  width: auto !important;
  height: 100% !important;
}
// Removed title
#mm-align-panel #ModelAlignmentPanel > .docking-panel-title {
  display: none;
}
// Removed edit button
#mm-align-panel #ModelAlignmentPanel #EditPositionButton {
  display: none;
}
// Tab Headers
#mm-align-panel #ModelAlignmentPanel > div {
  padding: 0 !important;
}
#mm-align-panel #ModelAlignmentPanel #TabHeaders {
  background-color: var(--v-primary-lighten1) !important;
  color: white;
  border-width: 1px 1px 0 1px !important;
  border-radius: 4px 4px 0 0;
  border-style: solid !important;
  border-color: rgba($color: black, $alpha: 0.12) !important;
  padding-bottom: 1px;
}
#mm-align-panel #ModelAlignmentPanel #TabHeaders .tab-header {
  cursor: pointer;
}
#mm-align-panel #ModelAlignmentPanel #TabHeaders .selected {
  color: var(--v-secondary-base);
  border-bottom-color: var(--v-secondary-base);
}
#mm-align-panel #ModelAlignmentPanel #TabHeaders .tab-header:hover {
  color: var(--v-secondary-base);
}
#ModelSection > div {
  // background-color: #eeeeee !important;
  margin-top: 0px !important;
}
#mm-align-panel #ModelSection {
  // background-color: #eeeeee !important;
  margin-bottom: 15px;
}
// Select dark mode
#mm-align-panel.dark-theme #ModelSection .model-select-combo,
#mm-align-panel.dark-theme input {
  background-color: #1e1e1e;
  border: 1px solid rgba($color: rgb(255, 255, 255), $alpha: 0.42) !important;
  border-radius: 3px;
  color: white !important;
  padding: 3px;
  margin-right: 5px;
}
// Select
#mm-align-panel #ModelSection .model-select-combo,
#mm-align-panel input {
  border: 1px solid rgba($color: black, $alpha: 0.2);
  border-radius: 3px;
  padding: 3px;
  margin-right: 5px;
}
// Input label
#mm-align-panel div:has(+ input) {
  margin-left: 3px;
}
// Align panel background light/dark
#mm-align-panel.dark-theme #ModelAlignmentPanel > div {
  background-color: #1e1e1e !important;
}
#mm-align-panel.light-theme #ModelAlignmentPanel > div {
  background-color: white !important;
}
#mm-align-panel.dark-theme
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(3)
  > div,
#mm-align-panel.dark-theme
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(4)
  > div,
#mm-align-panel.dark-theme
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(5)
  > div,
#mm-align-panel.dark-theme #ModelSection {
  background-color: #242424 !important;
}
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(3)
  > div,
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(4)
  > div,
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(5)
  > div,
#mm-align-panel #ModelSection {
  background-color: rgba($color: #eeeeee, $alpha: 0.2) !important;
}
#mm-align-panel #ModelSection {
  border: 1px solid rgba($color: black, $alpha: 0.12) !important;
  border-radius: 4px;
  color: white;
}
#mm-align-panel #ModelSection > div {
  border-bottom: 1px solid white;
  background-color: var(--v-primary-lighten1) !important;
  border-radius: 4px 4px 0 0;
  color: white;
}
// Sections
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(3) div,
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(4) div,
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(5) div {
  margin-top: 0 !important;
}
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(3),
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(4),
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:nth-child(5) {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgba($color: black, $alpha: 0.12);
  border-radius: 0 0 4px 4px;
}
// Removed save button
#mm-align-panel #ModelAlignmentPanel > div:nth-child(4) > div:last-child {
  display: none !important;
}
// All buttons
#mm-align-panel .adsk-button {
  cursor: pointer;
  margin-left: 4px;
  border: 1px solid var(--v-primary-base);
  border-radius: 3px;
  background-color: #eae7e7 !important;
  color: var(--v-primary-base);
  text-transform: uppercase;
  font-weight: 500;
  height: auto !important;
}
#mm-align-panel.dark-theme .adsk-button {
  background-color: #242424 !important;
}
// All header border
#mm-align-panel div[id*="Header"],
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(3)
  > div:nth-child(3) {
  border-top: 1px solid #d8d8d8 !important;
}
// Apply buttons
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(4)
  > div:nth-child(7),
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(5)
  > div:nth-child(9) {
  justify-content: end;
}
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(4)
  > div:nth-child(7)
  div,
#mm-align-panel
  #ModelAlignmentPanel
  > div:nth-child(4)
  > div:nth-child(5)
  > div:nth-child(9)
  div {
  transform: none !important;
  padding: 0 10px;
}
</style>