  /*
    Method to select "url" of icon asset according to extention of file:
    * rvt = revt;
    * ifc =
    * nwc = naviswork
    */

    export function getFileIconExt(ext) {
        let url = "";
        var images = require.context("../assets/", false, /\.png$/);
        switch (ext) {
          case "rvt":
            url = images("./autodesk-revit.png");
            break;
          case "ifc":
            url = images("./ifc-icon.png");
            break;
          case "nwd":
          case "nwc":
            url = images("./navisworks-icon.png");
            break;
          case "dwg":
            url = images("./dwg-icon.png");
            break;
          case "obj":
            url = images("./obj-icon.png");
            break;
          case "stl":
            url = images("./stl-icon.png");
            break;
          case "dgn":
            url = images("./DGN-icon.png");
            break;
          case "iwm":
            url = images("./iwm-icon.png");
            break;
          default:
            url = images("./file-icon.png");
        }
        return url;
}