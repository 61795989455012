<template>
  <v-menu bottom min-width="150px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list id="version-action-list" dense>
      <!-- DOWNLOAD -->
      <v-list-item class="version-action-item">
        <v-list-item-content  class="version-action-item-content justify-center">
              <download-action-button :forMenu="true"  :filesItem="fileItem"></download-action-button>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- old -->
    <!-- <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-btn @click="downloadFile" depressed rounded text>
            <v-icon>mdi-download</v-icon>
            Download source file
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card> -->
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import DownloadActionButton from './wrappers/DownloadFilesActionDialog'
//import axios from "axios";

export default {
  props: {
    fileItem: {
      default: null,
    },
  },
   components: {
    DownloadActionButton,
   },
  data: () => ({
    downloadProcessStatus: false
  }),
  computed: {
    ...mapGetters([
      "isLogged",
      "userProfile",
       "axiosSettings",
      "forgeToken",
      "documentTypeName",
      "itemTypeName",
      "versionTypeName",
    ]),
  },
  methods: {
    //OLD Methods of dowload: see DownloadFileActionDialog.vue
   /*
    async downloadCall(){
        this.downloadProcessStatus = true;
       await this.downloadFile();
      this.downloadProcessStatus = false;
    },
    async downloadFile() {
      let id = this.fileItem.itemId;
      let projectId = this.fileItem.projectId;
      let type = this.fileItem.type;
      console.log(id);
      let th = this;

      if (this.fileItem.type == th.documentTypeName) {
        id = this.fileItem.id;
      }

      let settings = JSON.parse(JSON.stringify(th.axiosSettings));

      settings["params"] = { projectId: projectId, id: id, type: type };

      axios
        .get("api/forge/documentToDownload", settings)
        .then(function (response) {
          //

          settings.method = "get";
          settings.url = response.data;

          // if (settings.headers) {
          //   settings.headers["Authorization"] =
          //     "Bearer " + th.forgeToken.access_token;
          // } else {
            settings.headers = {
              Authorization: "Bearer " + th.forgeToken.access_token,
           // };
          }

          settings.responseType = "blob";
          
          axios(settings).then(function (response1) {
            console.log(response1.data)
            var fileURL = window.URL.createObjectURL(response1.data);
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", th.fileItem.text);
            document.body.appendChild(fileLink);
            fileLink.click();
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      //
    },
    deleteFile() {},
    */
  },

};
</script>

<style lang="css" scoped>

  #vcad-action-list{
    background-color: #FFFFFF;
  }
  .vcad-action-item{
    min-height: 30px !important;
  }
  .vcad-action-item-content{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
</style>