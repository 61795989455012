<!------------------------------------------------
**************************************************
Author: m.vannoli
Date: 21/12/2021
Comments: Component general info (vcad bim360)
**************************************************
------------------------------------------------->
<template>
  <v-card
    align="center"
    class="mt-2 elevation-0 pt-2 pa-3 overflow-y-auto"
    max-height="55vh"
  >
    <!-- <v-img
      max-width="500"
      max-height="400"
      class="align-end"
      height="230px"
      src="@/assets/vcadacc.png"
    >
    </v-img> -->
    <v-card-text class="text--primary">
      <h2 class="text-black mb-4">
        Welcome to
        <b>Vcad for Autodesk Construction Cloud<span>&#174;</span></b>
      </h2>
      <p>
        To use Vcad, please activate the App in Autodesk Construction Cloud<span
          >&#174;</span
        >
        Account Admin area. If you need more information, follow the
        instructions described in the
        <a
          href="https://apps.autodesk.com/BIM360/en/Detail/HelpDoc?appId=8628266868333693275&appLang=en&os=Web"
          target="_blank"
          >Autodesk<span>&#174;</span> App Store page</a
        >.
      </p>
      <p>
        If you need help, feel free to
        <a href="https://www.bimservices.it/vcad-demo-request/" target="_blank"
          >contact us.</a
        >
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div align="right">
        <v-btn class="vcad-btn" outlined small awdtext :href="'https://www.bimservices.it/'" target="_blank">
          Visit us<v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "isLogged",
      "projects",
      "selectedProject",
      "showPreview",
      "showTemplates",
      "currentForgeFileData",
      "userProfile",
      "currentHub",
      "hubList",
      "isLoading",
      "axiosSettings",
      "slotCount",
      "vcadFileList",
      "axiosErrorState",
      "slotUsedCount",
      "trialMode",
      "subscriptionStatus",
      "expirationDate",
      "snackbarInfo",
      "snackbarIsVisible",
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>