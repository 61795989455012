
import Vue from 'vue';


import SensorDataDisplayer from "./components/SensorDataDisplayer";

import store from './store'
import vuetify from './plugins/vuetify';

//eslint-disable-next-line
export default class VcadMarkupPanel extends Autodesk.Viewing.UI.DockingPanel {

    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    constructor (viewer, options) {
  //console.log("VcadTemplatePanelconstructor");
      super (viewer.container, options.id, options.title, {
        addFooter: false
      })
 
       this.container.classList.add('docking-panel')

     

       this.container.style.top = "455px";
       this.container.style.left = "592px";
       this.container.style.width = "auto";
       this.container.style.height = "auto";
       this.container.style.resize = "auto";
     

  let div =  document.createElement('div');
  div.id="vcadMarkupPanelContent"
  
      this.container.appendChild(
        div) 
   
        new Vue({
          store,
          vuetify,
          render: h => h(SensorDataDisplayer)
        }).$mount('#vcadMarkupPanelContent')
   
      }


    
  
    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    initialize () {
  
      super.initialize()
  
      this.viewer = this.options.viewer
  
      this.footer = this.createFooter()
  
      this.container.appendChild(
          this.footer)
    }
  

    setData(data)
    {
      store.commit("setSensorData",data);
    }

    setPosition(x,y)
{

  this.container.style.left = x + 'px';
  this.container.style.top = y + 'px';

  console.log("vcadmarkuppanel position " + x + ' ' + y);
}

    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    setVisible (show) {
  
      super.setVisible(show)
  
      if (show) {
  
        // this.reactNode = ReactDOM.render(
        //   <ReactPanelContent/>,
        //   this.DOMContent)
  
      } else if (this.reactNode) {
  
        // ReactDOM.unmountComponentAtNode(
        //   this.DOMContent)
  
        this.reactNode = null  
      }
    }
  }