<template>
  <div>
    <div class="d-flex flex-column">
      <div class="mt-5">
        <div class="d-flex justify-center pa-2">
          <v-progress-circular
            class=""
            indeterminate
            :width="7"
            :size="70"
            color="secondary"
            v-if="radioIsLoading"
          ></v-progress-circular>
        </div>
        <v-radio-group
          v-if="!radioIsLoading"
          mandatory
          id="dataset-radio-group"
          v-model="selectedDataset"
        >
          <v-radio
            v-for="data in dataset"
            :key="data"
            :label="formatLabel(data)"
            :value="data"
            color="secondary"
            class="ml-4"
            style="text-transform: capitalize"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="align-self-center">
        <v-btn
          outlined
          @click="downloadParquetData()"
          color="primary"
          class="vcad-btn ma-4"
          :loading="loadingButton"
          ><v-icon class="mr-1">mdi-download-box</v-icon>Download</v-btn
        >
      </div>
      <v-text-field
        class="pa-4 flex-grow-0"
        outlined
        persistent-hint
        dense
        :hint="`URL to directly download the data file. Download  <a href='https://www.tadviewer.com' target='_blank'>TAD Viewer</a> to open this file format.`"
        label="Download URL"
        v-model="getDownloadUrl"
        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
        append-outer-icon="mdi-content-copy"
        v-if="authModeOn == true"
        :type="showKey ? 'text' : 'password'"
        name="txtFileKey"
        readonly
        @click:append-outer="copyText"
        @click:append="showKey = !showKey"
      >
        <template v-slot:message="{ message }">
          <span v-html="message"></span> </template
      ></v-text-field>
    </div>
  </div>
</template>
       

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { vcadDataset } from "../../utils/VcadDatasets";
import { VcadSlotKey } from "../../utils/VcadSlotKey";

export default {
  props: {
    slotGuid: {
      type: String,
      required: false,
    },
    fileId: {
      type: String,
      required: false,
    },
    fileType: {
      type: String,
      required: false,
    },
    projectId: {
      type: String,
      required: false,
    },
    selectedView: {
      type: Object,
      required: false,
    },
    vcadViewList: {
      default: null,
    },
    fileTypeNumber: {
      type: Number,
    },
    vcadItem: {
      required: false,
    },
    federatedSlotGuids: {
      type: Array,
      required: false,
      validator: (propVal) => {
        if (!Array.isArray(propVal)) {
          return false;
        }
        return propVal.every((item) => typeof item === "string");
      },
    },
  },
  components: {},
  data() {
    return {
      loadingButton: false,
      dataset: [],
      selectedDataset: "",
      showKey: false,
      baseURL: "",
      fileKey: "",
      radioIsLoading: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["axiosSettings"]),
    authModeOn() {
      return this.vcadItem?.authType == 1 || this.vcadItem?.authType == 3;
    },
    getDownloadUrl() {
      let datasetWithExt;
      // *If the file type is not IFC remove numbers, underscores and hyphens.
      if (this.fileTypeNumber !== 11) {
        datasetWithExt =
          this.selectedDataset.replace(/[0-9_-]/g, "") + ".parquet";
      } else {
        datasetWithExt = this.selectedDataset + ".parquet";
      }
      let params = {
        guidFile: this.slotGuid,
        type: "parquet",
        viewableGuid: this.selectedView?.guid,
        entryname: datasetWithExt,
      };
      let paramsString = "";

      for (let key in params) {
        paramsString += `${key}=${params[key]}&`;
      }

      paramsString = paramsString.slice(0, -1);
      let urlString = this.baseURL + "/file/GetFile?" + paramsString;

      // Append API key to the urlString
      urlString += `&x-vcad-auth=${this.fileKey}`;
      return urlString;
    },
  },
  methods: {
    ...mapActions(["showSnackbar"]),
    async getBaseUrl() {
      return axios
        .get(
          "api/vcad/file/getUrl",
          JSON.parse(JSON.stringify(this.axiosSettings))
        )
        .then((res) => {
          this.baseURL = res.data;
        });
    },
    async setKey() {
      let fileGuid;
      this.fileKey = "";
      if (this.authModeOn) {
        try {
          // IF the user is downloading a template for a federation
          if (
            this.vcadItem?.isFederation &&
            this.federatedSlotGuids?.length > 0
          ) {
            fileGuid = this.federatedSlotGuids[0];
          } else {
            // IF the user is downloading a template for a single file
            fileGuid = this.vcadItem.id;
          }
          this.fileKey = await VcadSlotKey(fileGuid).then((res) => {
            if (res.error !== 0) {
              throw res.descr;
            }
            if (!res.descr) {
              throw "Error loading user report key.";
            }
            return res.descr;
          });
        } catch (error) {
          this.showSnackbar({
            text: "Error loading user report key.",
            color: "error",
            timeoutTime: 2000,
          });
          console.error(error);
        }
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.getDownloadUrl);
      this.showSnackbar({
        text: "Copied URL to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    formatLabel(data) {
      // FORMAT LABELS REMOVING NUMBERS, UNDESCORE AND HYPHENS IN DATA
      return data.replace(/[0-9_-]/g, "");
    },
    async downloadParquetData() {
      this.loadingButton = true;
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.slotGuid;
      let datasetWithExt;
      // *If the file type is not IFC remove numbers, underscores and hyphens. Consider moving this operation to the server-side for consistency.
      if (this.fileTypeNumber !== 11) {
        datasetWithExt =
          this.selectedDataset.replace(/[0-9_-]/g, "") + ".parquet";
      } else {
        datasetWithExt = this.selectedDataset + ".parquet";
      }

      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        guidFile: guidFile,
        type: "parquet",
        viewableGuid: viewableGuid,
        entryname: datasetWithExt,
      };

      //call Api
      return axios
        .get("api/vcad/file/getFile", defs)
        .then((data) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );
          this.downloadDataset(fileURL, datasetWithExt);
        })
        .then(() => {
          return (this.loadingButton = false); //return false to loading button in App.vue
        });
    },
    downloadDataset(fileURL, datasetWithExt) {
      //dataset + extension (.parquet)
      let fileName = datasetWithExt.toLowerCase();

      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    async svfVersExists() {
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.slotGuid;
      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));

      defs["params"] = {
        guidFile: guidFile,
        type: "parquet",
        viewableGuid: viewableGuid,
        entryname: "svfVers.parquet",
      };

      //call Api
      return axios
        .get("api/vcad/file/fileExists", defs)
        .then((data) => {
          this.radioIsLoading = true;
          if (data.data == false) {
            let datasetToRemove = this.dataset.indexOf("10_svfVers");

            if (datasetToRemove !== -1) {
              this.dataset.splice(datasetToRemove, 1);
            }
          }
        })
        .finally(() => {
          this.radioIsLoading = false;
        });
    },
  },
  mounted() {
    this.dataset = vcadDataset(this.fileTypeNumber);
    this.selectedDataset = this.dataset[0];
    this.setKey();
    this.getBaseUrl();
    this.svfVersExists();
  },
};
</script>

<style lang="css" >
#dataset-radio-group {
  flex-wrap: wrap;
  max-height: 10em;
}
</style>