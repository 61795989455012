<!---------------------------------------------
***********************************************
Author: m.vannoli
Date: 02/11/2021
Comments: Component aims to perform color picker
***********************************************
---------------------------------------------->
<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="modalColorPickerField"
      :return-value.sync="fieldValue"
      persistent
      
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="fieldValue"
          prepend-icon="mdi-format-color-fill"
          readonly
          v-bind="attrs"
          v-on="on"
          chips
          :label="label"
        >
          <template v-slot:append>
            <v-avatar :color="String(fieldValue)" size="25"> </v-avatar>
          </template>
        </v-text-field>
      </template>
      <v-card>
        <v-card-title class="lighten-2">
          {{ label }}
        </v-card-title>
        <v-card-text>
          <v-color-picker
            class="elevation-0 ma-2"
            show-swatches
            width="500px"
            flat
            elevation="15"
            mode="rgba"
            v-model="fieldValue"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn    outlined
            small
            class="vcad-btn" color="primary"  @click="cancel()"
            >cancel</v-btn
          >
          <v-btn  outlined
            small
            class="vcad-btn" color="primary"  @click="$refs.dialog.save(fieldValue)"
            >apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script >
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      modalColorPickerField: false,
    };
  },
  computed: {
    fieldValue: {
      get() {
        if (this.value) {
          return this.value;
        } else {
          return "#ffffff";
        }
      },
      set(newVal) {
        if (this.value) {
          this.updateValue(newVal);
        } else {
          this.updateValue("#ffffff");
        }
      },
    },
  },
  methods: {
    /*
    update value
    */
    updateValue(newVal) {
      this.$emit("input", newVal);
      this.$emit("saveColor", newVal);
    },
    /*
    come back to the previous form
    */
    cancel() {
      this.modalColorPickerField = false;
    },
  },
  props: {
    value: {
      type: [String, Object],
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>

<style lang="css" scoped>
</style>