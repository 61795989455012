<template>
  <div>
    <v-snackbar
      :value="isVisible"
      :color="customColor"
      :timeout="customTimeout"
      dark
      dense
      app
      bottom
    >
      <div class="d-inline-flex justify-space-between align-center" style="width:100%">
        <span>{{text}}</span>
        <v-progress-circular  
          class="custom-linear-transition"
          color="white"
          :size="25"
          :width="1"
          :value="progressVal"
        >
          <v-btn icon>
            <v-icon dark small @click="closeSnackbar()">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-progress-circular>
        
      </div>
    </v-snackbar>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  export default {
    props:{
      /**
       * Snackbar visibility
       */
      value:{
        type: Boolean,
        required: true
      },
      /**
       * Snackbar text
       */
      text:{
        Type: String,
        required: true
      },
      /**
       * Set a custom timeout expressed in milliseconds.
       * Note: set "-1" to disable timeout.
       */
      customTimeout:{
        type: Number,
        required: false,
        default: 1000
      },
      /**
       * Set a custom color for the snackbar
       */
      customColor:{
        type: String,
        reuired: false,
        default: 'primary'
      }
    },
    data(){
      return{
        timeoutInterval: {},
        progressVal: 100
      }
    },
    computed:{
      isVisible:{
        get(){
          return this.value;
        }
      }
    },
    watch:{
      value(newVal){
        // Set progress interval for visible snackbar
        if(newVal){
          this.progressVal = 100;
          this.timeoutInterval = {}
          this.setProgressInterval();
        }
      }
    },
    methods:{
      ...mapActions(['resetSnackbar']),
      /**
       * Emit value changes to parent component.
       */
      updateValue(newVal) {
        if(!newVal){
          this.emitClose();
        }
        this.$emit("input", newVal);
      },
      emitClose(){
        this.$emit("closeSnack");
      },
      /**
       * Set progress interval and start counting timeout.
       */
      setProgressInterval(){
        if(this.customTimeout > -1){
          // Calc required interval time
          let stepInterval = this.customTimeout/100;
          this.progressVal = 0;
  
          // Set interval
          this.timeoutInterval = setInterval(() => {
            // When complete (100%) clear interval a reset progressVal
            if(this.progressVal === 100){
              this.closeSnackbar();
              return;
            }
            // increase progressVal
            this.progressVal += 1;
          }, stepInterval)
        }
      },
      /**
       * Reset values and close snackbar.
       */
      closeSnackbar(){
        this.progressVal = 100;
        clearInterval(this.timeoutInterval);
        this.updateValue(false);
        this.resetSnackbar();
      }
    }
  }
</script>

<style lang="css">
  .custom-linear-transition .v-progress-circular__overlay {
    transition: all 0.1s linear;
  }
</style>