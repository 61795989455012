<!------------------------------------------------
**************************************************
Author: m.vannoli
Date: 21/12/2021
Comments: Component to configurate settings of vcadBim360
**************************************************
------------------------------------------------->
<template>
  <div class="text-center">
    <!-- <div id="debug">showDialog: {{showDialog}}</div> -->
    <v-dialog v-model="showDialog" :width="dialogWidth" persistent>
      <!-- <template v-slot:activator="{ on }">
        <v-btn icon @click="openConfigDialog()" v-bind:on="on"
          ><v-icon>mdi-dots-horizontal</v-icon></v-btn
        >
      </template> -->
      <v-card >
        <v-toolbar dense flat dark color="primary mb-5">
          <v-toolbar-title v-if="title">
            <v-btn fat v-if="info" icon :href="info" target="_blank">
              <v-icon>mdi-information</v-icon> </v-btn
            ><span class="ml-1">{{ title }}</span></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeConfigDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pl-0">
          <v-row align="start">
            <v-col cols="4">
              <v-list>
                <v-list-item-group v-model="model">
                  <v-list-item
                  color="primary"
                    @click="manageButtons(item.key)"
                    v-for="(item, i) in items"
                    :key="`item-${i}`"
                  >
                    <v-list-item-icon>
                      <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{item.text}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider vertical inset></v-divider>
            </v-col>
            <v-col cols="8">
              <!--Theme Color Component-->
              <vcad-theme-color v-if="openColor"></vcad-theme-color>
              <!--General Information-->
              <vcad-general-info v-if="openGeneralInfo"></vcad-general-info>
              <!-- Privacy Information-->
              <vcad-privacy-info  v-if="openPrivacy"></vcad-privacy-info>
              <!-- Account Information-- TODO !! -->
              <!--<filter-profile-list v-if="!openColor&&!openGeneralInfo&&!openPrivacy" ></filter-profile-list>-->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
        <slot name="actions" v-bind:contentData="contentProps"></slot>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VcadDialogThemeColor from "./configuration/VcadDialogThemeColor";
import VcadGeneralInfo from "./configuration/VcadGeneralInfo";
import VcadPrivacyInfo from "./configuration/VcadPrivacyInfo";
export default {
  components: {
    "vcad-theme-color": VcadDialogThemeColor,
    "vcad-general-info": VcadGeneralInfo,
    "vcad-privacy-info": VcadPrivacyInfo,
    // "vcad-account-info": VcadAccountInfo,
    //"filter-profile-list": VcadProfileList
  },

  props: {
    /**
     * Model to be passed as v-model of v-dialog.
     */
    value: {
      type: Boolean,
      required: false,
    },
    /**
     * Set a title for the dialog
     */
    title: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Data required for rendering in the dialog "content" slot.
     */
    contentProps: {
      type: Object,
      required: false,
      default: null,
    },
    /**
     * Set dialog width.
     */
    dialogWidth: {
      type: String,
      required: false,
      default: "600",
    },

    /**
     * set info button (url)
     */
    info: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      openColor: true,
      openGeneralInfo: false,
      openAccountInfo: false,
      openPrivacy: false,
      checkDark: false,
      model: 0,
      items: [
        {
          icon: "mdi-invert-colors",
          text: "Theme color",
          key: "COLOR",
        },
        {
          icon: "mdi-lock",
          text: "Privacy Policy",
          key: "PRIVACY",
        },
        {
          icon: "mdi-information",
          text: "General Info",
          key: "INFO",
        },
          // {
          //   icon: "mdi-account",
          //   text: "Account",
          //   key: "ACCOUNT-INFO",
          // },
      ],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.updateValue(newVal);
      },
    },
  },
  methods: {
    /**
     * Emit update of v-model to parent component.
     * @param {Boolean} newVal new value to emit
     */
    updateValue(newVal) {
      this.$emit("input", newVal);
    },

    /*
     * Open Dialog of configuration dialog
     */

    openConfigDialog() {
      this.updateValue(true);
  
    },

    /*
     * Close Dialog of configuration dialog
     */
    closeConfigDialog() {
      this.updateValue(false);
    },

    /*
     * manage config menu of buttons
     */
    manageButtons(params) {
      switch (params) {
        case "COLOR": {
          //theme color btn
          this.openColor = true;
          this.openGeneralInfo = false;
          this.openAccountInfo = false;
          this.openPrivacy = false;
          break;
        }
        // case "ACCOUNT-INFO": {
        //   //account info btn
        //   this.openColor = false;
        //   this.openGeneralInfo = false;
        //   this.openAccountInfo = true;
        //   this.openPrivacy = false;
        //   break;
        // }
        case "INFO": {
          //general info btn
          this.openColor = false;
          this.openGeneralInfo = true;
          this.openAccountInfo = false;
          this.openPrivacy = false;
          break;
        }
        case "PRIVACY": {
          this.openPrivacy = true;
          this.openColor = false;
          this.openGeneralInfo = false;
          this.openAccountInfo = false;
          break;
        }
        default: {
          //otherwise nothing
          this.openColor = false;
          this.openGeneralInfo = false;
          this.openAccountInfo = false;
          this.openPrivacy = false;
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>