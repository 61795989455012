import store from "../store/index"

export function errorManager(error) {
    console.log(error);
    if (store.getters.axiosErrorState.state == 0) {
        if (error && error.response && error.response.status == 401) {
            //access denied - token
            
          let errorn =   error.response.data.error + ' - ' + error.response.data.descr;
            
            if (error.response.data.error == 2) {
                store.dispatch("setAxiorErrorState", {
                    state: 1,
                    errorNr: errorn ,
                    message: "Access denied - token",
                    route: "",
                    isBlocking: true
                })
            }
            //Vcad user not found
            if (error.response.data.error == 1001) {
                store.dispatch("setAxiorErrorState", {
                    state: 1,
                    errorNr: errorn,
                    message: "Vcad not enabled for current user",
                    route: "",
                    isBlocking: true
                })
            }

            if (error.response.data.error == 2001) {
                store.dispatch("setAxiorErrorState", {
                    state: 1,
                    errorNr: errorn,
                    message: "Import Vcad Error",
                    route: "",
                    isBlocking: false
                })
            }

            // 
            if (error.response.data.error == 2002) {
                store.dispatch("setAxiorErrorState", {
                    state: 1,
                    errorNr: errorn,
                    message: "Vcad Conversion Error",
                    route: "",
                    isBlocking: false
                })
            }

        }
    }
}