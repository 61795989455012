<template>
  <div>

    <!-- TEMPLATE MESSAGE -->
    <!-- <v-alert
      text
      class="mb-8"
      :type="infoMsg.type"
      style="opacity: 0.88"
      :icon="infoMsg.icon"
      outlined
      dense
    >
      <div>
        {{ infoMsg.text }}
        <a
          target="blank"
          class="text-decoration-underline"
          color="primary"
          :href="infoMsg.docsLink"
          >Learn more</a
        >
      </div>
    </v-alert> -->
    <v-select
      class="flex-grow-0 mb-2 mt-1"
      style="width: 50%"
      label="View"
      v-model="selectedView"
      return-object
      v-if="visibleView"
      item-text="name"
      item-key="guid"
      :items="vcadCurrentViews"
      hint="Available views and phases"
      append-outer-icon="mdi-content-copy"
      @click:append-outer="copyViewGuid(vcadItem)"
      outlined
      persistent-hint
      dense
    >
      <template v-slot:append>
        <span class="grey--text text-caption" style="margin-top: 2px">
          ( {{ vcadCurrentViews.length }} views)
        </span>
        <v-icon>mdi-menu-down</v-icon>
      </template>
    </v-select>

    <v-tabs
      style="border: 1px solid #f0f0f0"
      vertical
      class="mb-2 mr-1"
      grow
      v-model="activeTab"
      dense
    >
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab
        style="border-right: 1px solid #f0f0f0"
        class="font-weight-black"
        v-for="tab in tabs"
        :key="tab.tabKey"
        :disabled="tab.isVisible"
        dense
        >{{ tab.tabName }}</v-tab
      >
      <v-tab-item :transition="false" v-for="tab in tabs" :key="tab.tabKey">
        <v-data-table
          class="vcad-template-table-scrollable rounded-0"
          :headers="headers"
          height="30vh"
          :show-select="false"
          :items="getTemplates(tab.tabName)"
          item-key="name"
          dense
          disable-pagination
          hide-default-footer
          fixed-header
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.label || item.name }}
          </template>
          <template v-slot:[`item.version`]="{ item }">
            <!-- <v-chip class="primary" outlined label small> -->
            V{{ printVersion(item) }}
            <!-- </v-chip> -->
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              class="vcad-btn ma-2"
              small
              color="primary"
              :disabled="isDownloading"
              @click="downloadTemplate(item)"
            >
              <v-icon class="mr-1">mdi mdi-cloud-download</v-icon>
              template
            </v-btn>

            <template v-if="item.isPdfPresent">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :disabled="isDownloading"
                    @click="downloadHelp(item)"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon>mdi-file-pdf-outline</v-icon>
                  </v-btn>
                </template>
                <span>Download help file</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <div class="d-flex align-center justify-space-between">
      <v-text-field
        class="pt-5 flex-grow-0"
        outlined
        persistent-hint
        style="width: 45%"
        dense
        hint="Key required when loading the extacted data"
        v-if="authModeOn == true"
        v-model="fileKey"
        :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
        append-outer-icon="mdi-content-copy"
        :type="showKey ? 'text' : 'password'"
        name="txtFileKey"
        label="API Key"
        readonly
        @click:append-outer="copyText"
        @click:append="showKey = !showKey"
      ></v-text-field>
      <v-text-field
        class="pt-5 flex-grow-0"
        outlined
        persistent-hint
        style="width: 45%"
        dense
        hint=""
        v-if="authModeOn == true"
        append-outer-icon="mdi-content-copy"
        label="Slot ID"
        readonly
        @click:append-outer="copySlotIdText(vcadItem)"
        :value="vcadItem.id"
      ></v-text-field>
    </div>
    <v-progress-linear v-if="isDownloading" indeterminate></v-progress-linear>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { VcadSlotKey } from "../utils/VcadSlotKey";
import sanitizefilename from "sanitize-filename";
// import messages from "../assets/messages/TemplateDialog.json";

export default {
  mounted() {
    // if(this.currentForgeFileData.vcadItem && this.currentForgeFileData.vcadItem.type){
    //   this.setVcadTemplateList(this.currentForgeFileData.vcadItem.type);
    // }
    this.setDefaultView();
    this.setkey();
  },

  props: {
    vcadData: {
      validator: (propVal) => {
        const propValCheck = typeof propVal === "object";
        const propValNameCheck =
          propVal.name && typeof propVal.name === "string";
        const propValTypeCheck =
          propVal.type && typeof propVal.type === "number";
        const propValIdCheck = propVal.id && typeof propVal.id === "string";

        if (
          propValCheck &&
          propValTypeCheck &&
          propValNameCheck &&
          propVal.authType &&
          propValIdCheck
        ) {
          return true;
        }
        return false;
      },
      required: false,
    },
    vcadViewList: {
      default: null,
    },
    federatedSlotGuids: {
      type: Array,
      required: false,
      validator: (propVal) => {
        if (!Array.isArray(propVal)) {
          return false;
        }
        return propVal.every((item) => typeof item === "string");
      },
    },
  },

  watch: {
    vcadCurrentViews() {
      this.setDefaultView();
      this.setkey();
      this.showKey = false;
    },
  },

  data() {
    return {
      yellowColor: "",
      favouriteTemplates: [],
      activeTab: 0,
      visibleView: false,
      showKey: false,
      fileKey: "",
      selectedView: null,
      // infoMsg: messages.templateDialogMsg,
      headers: [
        {
          text: "Template name",
          align: "center",
          sortable: false,
          value: "name",
          width: "30%",
          class: "primary--text text-uppercase py-2",
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
          value: "description",
          width: "40%",
          class: "primary--text text-uppercase py-2",
        },
        {
          text: "Version",
          align: "center",
          sortable: false,
          value: "version",
          width: "15%",
          class: "primary--text text-uppercase py-2",
        },
        {
          text: "Download",
          align: "center",
          sortable: false,
          value: "action",
          width: "15%",
          class: "primary--text text-uppercase py-2",
        },
      ],
      isDownloading: false,
    };
  },

  methods: {
    ...mapActions(["showSnackbar"]),
    /* Download view of templates =>
     * Url : https://bimapi.vcad.it/File/GetFile?guidFile={guidFile}&type=csvzip&x-vcad-auth={userkey}
     * API: api/vcad/file/getFile
     * params : guidFile - type - viewableGuid
     * Type : Get
     * method : method to call this url with right parameters and download csv for specific guidfile and guid of view
     * ps: only for revit files.
     * author: m.vannoli
     * date: 15/12/2021
     */
    async downloadCsvZipViews() {
      let viewableGuid = this.selectedView.guid;
      let guidFile = this.vcadItem.id;
      let th = this;
      let defs = JSON.parse(JSON.stringify(th.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        guidFile: guidFile,
        type: "csvzip",
        viewableGuid: viewableGuid,
      };
      let viewName = "";
      if (th.vcadItem.type == 7 || th.vcadItem.type == 8) {
        viewName = "_" + sanitizefilename(th.selectedView.name);
      }

      //call Api
      return axios
        .get("api/vcad/file/getFile", defs)
        .then((data) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );

          //file name + extension (.zip)
          var fileName =
            th.vcadItem.name.substr(
              0,

              th.vcadItem.name.lastIndexOf(".")
            ) +
            viewName +
            ".zip";
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .then(() => {
          return false; //return false to loading button in App.vue
        });
    },

    async setkey() {
      let fileGuid;
      this.fileKey = "";
      if (this.authModeOn) {
        try {
          // IF the user is downloading a template for a federation
          if (
            this.vcadItem?.isFederation &&
            this.federatedSlotGuids?.length > 0
          ) {
            fileGuid = this.federatedSlotGuids[0];
          } else {
            // IF the user is downloading a template for a single file
            fileGuid = this.vcadItem.id;
          }
          this.fileKey = await VcadSlotKey(fileGuid).then((res) => {
            if (res.error !== 0) {
              throw res.descr;
            }
            if (!res.descr) {
              throw "Error loading user report key.";
            }
            return res.descr;
          });
        } catch (error) {
          this.showSnackbar({
            text: "Error loading user report key.",
            color: "error",
            timeoutTime: 2000,
          });
          console.error(error);
        }
      }
    },
    copyViewGuid(){
      navigator.clipboard.writeText(this.selectedView.guid);
      this.showSnackbar({
        text: "Copied guid to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    copyText() {
      navigator.clipboard.writeText(this.fileKey);
      this.showSnackbar({
        text: "Copied key to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    copySlotIdText(vcadItem) {
      navigator.clipboard.writeText(vcadItem.id);
      this.showSnackbar({
        text: "Copied key to clipboard.",
        color: "primary",
        timeoutTime: 4000,
      });
    },
    setDefaultView() {
      this.selectedView = null;
      if (this.vcadCurrentViews && this.vcadCurrentViews.length > 1) {
        this.visibleView = true;
      }
      if (this.vcadCurrentViews && this.vcadCurrentViews.length > 0) {
        let defaultView = this.vcadCurrentViews.find((o) => o.name == "{3D}");
        // if (defaultView) {
        //   this.selectedView = defaultView;
        // }
        this.selectedView = defaultView
          ? defaultView
          : this.vcadCurrentViews[0];
      }
    },
    downloadTemplate(item) {
      console.log("downloadTemplate viewguid: " + this.selectedView.guid);
      this.isDownloading = true;

      let defs = JSON.parse(JSON.stringify(this.axiosSettings));
      defs["responseType"] = "blob";
      defs["params"] = {
        template: item.name,
        guidFile: this.vcadItem.id,
        type: this.vcadItem.type,
        viewableGuid: this.selectedView.guid,
      };
      let th = this;

      let viewName = "";

      if (this.vcadItem.type == 7 || this.vcadItem.type == 8) {
        viewName = "_" + sanitizefilename(this.selectedView?.name);
      }
      axios
        .get("api/vcad/templates/configure", defs)
        .then((data) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/x-zip-compressed" })
          );
          var fileName =
            th.vcadItem.name.substr(0, th.vcadItem.name.lastIndexOf(".")) +
            viewName +
            "__" +
            item.name;

          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .then(() => {
          this.isDownloading = false;
        });
    },
    downloadHelp() {},
    printVersion(item) {
      if (item.varVersion) {
        return item.varVersion;
      }
      return "";
    },
    getTemplates(tab) {
      let templates = this.vcadTemplateList
        .filter((temp) => tab === temp.tabName)
        .sort((a, b) => a.order - b.order);
      return templates;
    },
    // getDocLink(vcadItem) {
    //   let docLink = vcadDocsLink.find((file) => vcadItem?.type == file.type);
    //   if (!docLink) {
    //     docLink = vcadDocsLink.find((file) => null == file.type);
    //   }
    //   return docLink.link;
    // },
  },

  computed: {
    ...mapGetters([
      "axiosSettings",
      "projects",
      "selectedProject",
      "showPreview",
      "vcadTemplateList",
      "currentForgeFileData",
      "currentViews",
    ]),
    tabs() {
      const tabList = this.vcadTemplateList
        .reduce((tabs, currTemplate) => {
          let tabExists = tabs.some((tab) => {
            return currTemplate.tabName == tab.tabName;
          });
          if (!tabExists) {
            const tabKey = currTemplate.tabName.toLowerCase();
            tabs.push({
              tabKey,
              tabName: currTemplate.tabName,
              tabOrder: currTemplate.tabOrder,
            });
          }
          return tabs;
        }, [])
        .sort((a, b) => a.tabOrder - b.tabOrder);
      return tabList;
    },
    vcadCurrentViews() {
      return this.vcadViewList ? this.vcadViewList : this.currentViews;
    },
    vcadItem() {
      return this.vcadData ? this.vcadData : this.currentForgeFileData.vcadItem;
    },
    authModeOn() {
      return this.vcadItem?.authType == 1 || this.vcadItem?.authType == 3;
    },
  },
};
</script>

<style scoped lang="scss">
</style>