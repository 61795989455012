<template>
  <v-dialog
    v-model="showDialog"
    :width="dialogWidth"
    persistent
    :fullscreen="fullscreen"
  >
    <template v-slot:activator="{ on }">
      <slot name="dialogActivator" v-bind:on="on"></slot>
    </template>
    <v-card :style="{ 'min-height': dialogMinHeight }" class="dialog-card">
      <v-toolbar
        dense
        flat
        dark
        :color="tabsProps ? 'primary mb-0' : 'primary mb-5'"
      >
        <v-img
          v-if="iconImg"
          max-height="25"
          max-width="25"
          :src="iconImg"
        ></v-img>
        <v-toolbar-title v-if="title">
          <v-btn fat v-if="info" icon :href="info" target="_blank">
            <v-icon>mdi-information</v-icon> </v-btn
          ><span class="ml-1">{{ title }}</span></v-toolbar-title
        >

        <v-spacer></v-spacer>
        <slot name="top"></slot>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="overflow: auto">
        <slot name="tabs" v-bind:contentData="tabsProps"></slot>
        <slot name="content" v-bind:contentData="contentProps"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions" v-bind:contentData="contentProps"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    iconImg: {
      type: String,
      required: false,
    },
    /**
     * Model to be passed as v-model of v-dialog.
     */
    value: {
      type: Boolean,
      required: false,
    },
    /**
     * Set a title for the dialog
     */
    title: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Data required for rendering in the dialog "content" slot.
     */
    contentProps: {
      type: Object,
      required: false,
      default: null,
    },
    tabsProps: {
      type: Object,
      required: false,
      default: null,
    },
    /**
     * Set dialog width.
     */
    dialogWidth: {
      type: String,
      required: false,
      default: "600",
    },
    info: {
      type: String,
      required: false,
      default: null,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogMinHeight: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.updateValue(newVal);
      },
    },
  },
  methods: {
    /**
     * Emit update of v-model to parent component.
     * @param {Boolean} newVal new value to emit
     */
    updateValue(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-card.theme--dark {
  background-color: #1b1b1b !important;
}
</style>