import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// dark mode (if dark mode or not)
let darkMode = false;  
if(localStorage.darkMode == "true"){
    darkMode = true;
}else{
    darkMode = false;
}


// color primary 
let primarycolor = "#303F9F"; // blue
if(localStorage.primaryColor != null){
    primarycolor = localStorage.primaryColor;
}else{
    primarycolor = '#303F9F'  // blue
}


// color secondary 
let secondarycolor = "#8b0202";  //vcad color (red)
if(localStorage.secondaryColor != null){
    secondarycolor = localStorage.secondaryColor;
}else{
    secondarycolor = '#8b0202'   //vcad color (red)
}


// Light theme
export const lightTheme = {
    // ---as before--
    // primary: colors.lightBlue.lighten4,
    // secondary: colors.red.lighten1,
    // primary: colors.cyan.darken4 ,//colors.purple.darken4,
    // primary: colors.blue.darken3 //default, 
    // secondary : colors.deepOrange.darken3  //default,
    // --------------
    primary: primarycolor,
    secondary: secondarycolor,
    accent: colors.red.darken4,
    error: colors.deepOrange.darken3,
    warning: colors.amber.darken2,
    info: colors.cyan, //colors.lightBlue.darken2,
    success: colors.lightGreen.darken2
};

// Dark theme
export const darkTheme = {
    primary: primarycolor , 
    secondary: secondarycolor,
    accent: colors.red.darken4,
    error: colors.deepOrange.darken3,
    warning: colors.amber.darken2,
    info: colors.cyan, 
    success: colors.lightGreen.darken2
};

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: lightTheme,
            dark: darkTheme
        },
        dark:darkMode 
    },
   
});

