<template>
    <div v-if="sensorData.length > 0">
        <!-- SINGLE VALUE FOR SENSOR -->
        <div v-if="!displayChart">
            <v-card dark class="tasnparent-dark-1 .transparent">
                <v-card-title primary-title>
                    Data values from {{sensorData[0].date}}
                </v-card-title>
                <v-card-text>
                    <v-list class="tasnparent-dark-4" dense>
                        <v-list-item
                            v-for="(sensorType, sensorTypeIndex) in sensorTypes" :key="'ITEM_' + sensorTypeIndex"
                        >
                                <v-row :justify="'space-between'" align="center">
                                    <v-col :cols="4" class="text-left py-1">
                                        <span class="caption">{{sensorType}}</span>
                                    </v-col>
                                    <v-col :cols="8" class="text-left py-1">
                                        <span class="body-1 font-weight-bold primary--text">{{singleDatePayload[sensorType]}}</span>
                                    </v-col>
                                </v-row>
                            
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>

        <!-- MULTIPLE VALUE FOR SENSOR -->
        <div v-if="displayChart">
            <v-tabs
                v-model="activeTab"
                color="primary"
                slider-color="primary"
            >
                <v-tab 
                    v-for="(sensorType, sensorTypeIndex) in sensorTypes"
                    :key="'TAB_' + sensorTypeIndex">{{sensorType}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
                <v-tab-item v-for="(sensorType, sensorTypeIndex) in sensorTypes" :key="'TAB-ITEM_' + sensorTypeIndex">  
                        <div>
                            <!-- <line-chart :chart-data="inputsByCategoryDict.chartDataCollection"></line-chart> -->
                        </div>
                    
                    {{labelDict[sensorType]}}
                    <!-- {{inputsByCategoryDict[sensorType]}} -->
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
 //   import LineChart from "../components/LineChart";

    export default {
        components:{
            // "line-chart": LineChart
        },
  
        data(){
            return {
                // sensorTypes: [],
                activeTab: null,
                displayChart: false,
                //inputsByCategoryDict: {}
                labelDict: {},
                dateDict: {},
                dataCollectionFull:{}
            }
        },
        computed:{
            /**
             * Pivoted values to use if data is relative to a single payload.
             */
        

sensorTypes(){
return this.getDistinctPropertyValues(this.sensorData, 'label');
},

        sensorData(){

         


           return this.$store.getters.sensorData!=null?this.$store.getters.sensorData:[];
        }
        ,
        
        singleDatePayload(){
                let payload = {};
                if(this.sensorData && this.sensorTypes && this.sensorTypes.length > 0){
                    this.sensorTypes.forEach(type => {     
                        let value = this.sensorData.filter(item => {
                            return item.label == type
                        } )[0].value;
                        payload[type] = value;
                    });
                }
                return payload;
            },
            // inputsByCategoryDict(){
            //     let payload = {};
            //     this.sensorTypes.forEach(type => {     
            //         let value = this.sensorData.filter(item => {
            //             return item.label == type
            //         } );
            //         payload[type] = value;
            //     });
            //     payload.chartDataCollection = this.calcDataCollection(payload);
            //     return payload;
            // }
        },
        methods:{
            getDistinctPropertyValues(items, propName){
                return items.map( item => item[propName])
                    .filter( (value, index, self) => self.indexOf(value) === index);
            },
            // calcInputsByCategoryDict(){
            //      let payload = {};
            //     this.sensorTypes.forEach(type => {     
            //         let value = this.sensorData.filter(item => {
            //             return item.label == type
            //         } );
            //         payload[type] = value;
            //     });
            //     payload.chartDataCollection = this.calcDataCollection(payload);
            //     return payload;
            // },
            setInputsByDateAndType(){
                this.sensorData.forEach(input => {
                    this.labelDict[input.label] = input;
                    this.dateDict[input.date] = input;
                })
                
            },

            calcDataCollection(){
               // let dataCollection = {};
                let labels = [];                    // All labels
                let dates = []                      // All dates of inputs

                labels = Object.keys(this.labelDict);
                dates = Object.keys(this.dateDict);

                // // Calc dataCollection for each property of "payload", except "chartDataCollection"
                // // // categories = Object.keys(payload).filter(category => category != 'chartDataCollection');

                // // // // categories.reduce()
                // // // dateList = this.sensorData.splice(this.sensorData.length - 10)
                // // //     .reduce( (dateList, currInput) => {
                // // //         return Array.from(new Set([...dateList, currInput.date]));
                // // //     }, [])



                // // let valuesByDate = new Array(dateList.length);
                
                // // for(let i = 0; i < valuesByDate.length; i++){
                // //     let tmp = new Array(categories.length);

                // //     for(let j = 0; j < categories.length; j++){
                // //         tmp[j] = this.sensorData.splice(this.sensorData.length - 10).find( input => {
                // //             console.log('categories[j]: ', categories[j]);
                // //             console.log('input.label: ', input.label);
                // //             console.log('dateList[i]: ', dateList[i]);
                // //             console.log('input.date: ', input.date);
                // //             return input.label == categories[j] && input.date == dateList[i]
                // //         })[0];
                // //     }
                // //     valuesByDate[i] = tmp;
                // // }

                // // console.log('valuesByDate: ', valuesByDate);

                // // // dateList.forEach(date => {
                // // //     let obj = {};
                // // //     let input = this.sensorData.filter(input => input.date = date)
                // // //     obj[date] = {
                // // //         date: date,
                // // //         category:     
                // // //     }
                // // // })

                let datasets = new Array(dates.length);

                for(let i = 0; i < datasets.length; i++){
                    let tmp = new Array(labels.length);
                    for(let j = 0; j < labels.length; j++){
                        tmp[j] = this.labelDict[labels[j]].value;
                    }
                    datasets[i] = tmp;
                }
                console.log(datasets[0]);

                return  {
                    labels: dates,
                    datasets: [
                    {
                        label: "Data One",
                        backgroundColor: "#f87979",
                        data: [1, 30],
                    },
                    {
                        label: "Data One",
                        backgroundColor: "#f87979",
                        data: [45, 76],
                    },
                    ],
                };
            }
        },
        mounted(){
           
            // Check id dataset requires charts or simple list of values
            this.displayChart = this.sensorTypes.length ==  this.sensorData.length ? false : true;

            if(this.displayChart){
                // this.inputsByCategoryDict = this.calcInputsByCategoryDict();
                this.setInputsByDateAndType();

                this.dataCollectionFull = this.calcDataCollection();
            }
        }

    }
</script>

<style lang="scss" scoped>
    .tasnparent-dark-1 {
        background-color: rgba(25, 25, 25, 0.90);
    }
    .tasnparent-dark-2 {
        background-color: rgba(25, 25, 25, 0.75);
    }
    .tasnparent-dark-3 {
        background-color: rgba(25, 25, 25, 50);
    }
    .tasnparent-dark-4 {
        background-color: rgba(25, 25, 25, 0);
    }
</style>